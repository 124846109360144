import {
  type BoxProps,
  Box,
  Button,
  Icon,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import * as _ from 'lodash-es';
import { memo, useEffect, useRef } from 'react';
import { InlineWidget as CalendlyWidget } from 'react-calendly';
import { useForm, useWatch } from 'react-hook-form';

import { HeartIcon } from '~/bundles/Classroom/icons';
import { BigButtonSelect } from '~/components/BigButtonSelect';
import Carousel from '~/components/Carousel';
import { GlowingEffect } from '~/components/effects/GlowingEffect';
import { FormatCurrency } from '~/components/intl';
import { Markdown } from '~/components/Markdown';
import { useAsync } from '~/lib/hooks/useAsync';
import { loadScript } from '~/lib/utils/loadScript';

import trustpilotImage from '../../../assets/images/logo-trustpilot.png';
import alumni1 from '../../../assets/images/PaymentPage/alumni-1.jpg';
import alumni10 from '../../../assets/images/PaymentPage/alumni-10.jpg';
import alumni11 from '../../../assets/images/PaymentPage/alumni-11.jpg';
import alumni12 from '../../../assets/images/PaymentPage/alumni-12.jpg';
import alumni13 from '../../../assets/images/PaymentPage/alumni-13.jpg';
import alumni14 from '../../../assets/images/PaymentPage/alumni-14.jpg';
import alumni15 from '../../../assets/images/PaymentPage/alumni-15.jpg';
import alumni16 from '../../../assets/images/PaymentPage/alumni-16.jpg';
import alumni17 from '../../../assets/images/PaymentPage/alumni-17.jpg';
import alumni2 from '../../../assets/images/PaymentPage/alumni-2.jpg';
import alumni3 from '../../../assets/images/PaymentPage/alumni-3.jpg';
import alumni4 from '../../../assets/images/PaymentPage/alumni-4.jpg';
import alumni5 from '../../../assets/images/PaymentPage/alumni-5.jpg';
import alumni6 from '../../../assets/images/PaymentPage/alumni-6.jpg';
import alumni7 from '../../../assets/images/PaymentPage/alumni-7.jpg';
import alumni8 from '../../../assets/images/PaymentPage/alumni-8.jpg';
import alumni9 from '../../../assets/images/PaymentPage/alumni-9.jpg';
import generalAlumniImage1 from '../../../assets/images/PaymentPage/alumni-general-1.jpg';
import medicalAssistantImage1 from '../../../assets/images/PaymentPage/alumni-medical-assistant-1.jpg';
import medicalAssistantImage2 from '../../../assets/images/PaymentPage/alumni-medical-assistant-2.jpg';
import medicalAssistantImage3 from '../../../assets/images/PaymentPage/alumni-medical-assistant-3.jpg';
import medicalAssistantImage4 from '../../../assets/images/PaymentPage/alumni-medical-assistant-4.jpg';
import medicalAssistantImage5 from '../../../assets/images/PaymentPage/alumni-medical-assistant-5.jpg';
import pharmacyTechImage1 from '../../../assets/images/PaymentPage/alumni-pharmacy-tech-1.jpg';
import pharmacyTechImage2 from '../../../assets/images/PaymentPage/alumni-pharmacy-tech-2.jpg';
import pharmacyTechImage3 from '../../../assets/images/PaymentPage/alumni-pharmacy-tech-3.jpg';
import { SelectPaymentPlanCountdownBanner } from './SelectPaymentPlanCountdownBanner';
import { type PaymentPlan, useSignupFlow } from './signupFlowData';
import { SignupPaymentDrawer } from './SignupPaymentDrawer';

export { trustpilotImage };

export const ScheduleACall: React.FC = () => {
  const {
    contact_data: contactData = {},
    show_schedule_a_call: showScheduleACall,
  } = useSignupFlow();

  if (!showScheduleACall) return null;

  return (
    <Box>
      <CalendlyWidget
        url="https://calendly.com/stepful/intro?hide_gdpr_banner=1"
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: 'c71a4b',
          textColor: '222525',
        }}
        prefill={{
          email: contactData.email,
          firstName: contactData.first_name,
          lastName: contactData.last_name,
          customAnswers: {
            a1: contactData.phone_number,
          },
        }}
      />
    </Box>
  );
};

const PaymentPlanOption: React.FC<{
  paymentPlan: PaymentPlan;
}> = ({ paymentPlan: pp }) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        filter="grayscale(100%)"
        _groupChecked={{ filter: 'none' }}
      >
        <Text color="gray.600" fontSize="sm">
          {pp.public_title}
        </Text>
        <Text>
          {pp.public_full_price_amount > pp.public_amount ? (
            <Text
              as="span"
              textDecoration="line-through"
              color="gray.500"
              mr="1.5"
              fontSize="2xl"
            >
              <FormatCurrency value={pp.public_full_price_amount} />
            </Text>
          ) : null}
          <Text as="span" fontSize="2xl" fontWeight="bold">
            <FormatCurrency value={pp.public_amount} />
          </Text>

          {pp.public_subtitle ? (
            <Text
              as="span"
              display="none"
              _groupChecked={{ display: 'inline-block ' }}
              fontSize="lg"
              fontWeight="bold"
              ml="1.5"
            >
              {pp.public_subtitle}
            </Text>
          ) : null}
        </Text>
        {pp.public_description ? (
          <Box
            display="none"
            _groupChecked={{ display: 'block' }}
            mt="4"
            color="gray.600"
            fontSize="sm"
          >
            <Markdown>{pp.public_description}</Markdown>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Trustpilot?: {
      loadFromElement: (el: HTMLElement) => void;
    };
  }
}

const dataToAttributes = (
  data: Record<string, string | number | null | undefined>,
  attrs = {} as Record<string, string>,
) => {
  Object.entries(data).forEach(([key, val]) => {
    if (val != null) attrs[`data-${_.kebabCase(key)}`] = val.toString();
  });
  return attrs;
};

type TrustpilotData = {
  templateId: string;
  theme?: 'light' | 'dark';
  locale?: string;
  styleWidth?: string;
  styleHeight?: string;
  tags?: string; // e.g. 'SelectedReview'
  starts?: string; // e.g. '3,4,5'
  reviewLanguages?: string; // e.g. 'en'
};

const TruspilotWidget_: React.FC<
  BoxProps & {
    data: TrustpilotData;
    children?: React.ReactNode;
  }
> = ({ data, children, ...props }) => {
  const { loading } = useAsync(
    () =>
      loadScript(
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      ),
    [],
  );

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!loading && ref.current) {
      ref.current.classList.add('trustpilot-widget');
      window.Trustpilot?.loadFromElement(ref.current);
    }
  }, [loading]);

  const allData = {
    businessunitId: '6112ca6198c5f8001de7069f',
    theme: 'light',
    locale: 'en-US',
    ...data,
  } satisfies TrustpilotData & { businessunitId: string };

  return (
    <Box ref={ref} {...props} {...dataToAttributes(allData)}>
      {/* children will be overridden by Trustpilot sdk */}
      {children}
    </Box>
  );
};
const TruspilotWidget = memo(TruspilotWidget_, () => true); // never re-render

const TrustpilotRating: React.FC = () => {
  return (
    <TruspilotWidget
      data={{
        templateId: '5419b637fa0340045cd0c936',
        styleWidth: '230px',
        styleHeight: '20px',
      }}
      h="20px"
      mx="-2"
    >
      <Skeleton display="inline-block" w="60px" h="70%" mr="2" ml="2" />
      <Skeleton display="inline-block" w="70px" h="65%" mr="2" />
      <Skeleton display="inline-block" w="70px" h="75%" />
    </TruspilotWidget>
  );
};

const SocialProof: React.FC = () => {
  return (
    <Box pt={{ base: 6, md: 12 }} pb={{ base: 24, md: 10 }} px="2" bg="gray.50">
      <Text textAlign="center" fontSize="md" fontWeight="bold" mb="2">
        Our Graduates Love Stepful
      </Text>

      <TruspilotWidget
        data={{
          templateId: '54ad5defc6454f065c28af8b',
          styleWidth: '100%',
          styleHeight: '240px',
          tags: 'SelectedReview',
          reviewLanguages: 'en',
        }}
        h="240px"
      >
        <Box
          h="full"
          w="full"
          display="flex"
          justifyContent="center"
          overflowX="hidden"
          px="8"
          pt="6"
          gap={6}
        >
          {Array.from({ length: 5 }).map((_a, i) => (
            <Skeleton key={i} flexShrink={0} w="max(200px, 18%)" h="60%" />
          ))}
        </Box>
      </TruspilotWidget>
    </Box>
  );
};

const SocialProofWithAlumniCarousel: React.FC = () => {
  const { curriculum } = useSignupFlow();

  const medicalAssistantImages = [
    medicalAssistantImage1,
    medicalAssistantImage2,
    medicalAssistantImage3,
    medicalAssistantImage4,
    generalAlumniImage1,
    medicalAssistantImage5,
  ];

  const pharmacyTechImages = [
    pharmacyTechImage1,
    pharmacyTechImage2,
    pharmacyTechImage3,
    generalAlumniImage1,
  ];

  const images =
    curriculum?.slug === 'ccma' ? medicalAssistantImages : pharmacyTechImages;

  return (
    <Box
      pt={6}
      pb={{ base: 24, md: 10 }}
      px="2"
      bg="gray.100"
      id="alumni-carousel"
    >
      <Text
        textAlign="center"
        fontSize={{ base: 'md', md: '2xl' }}
        fontWeight="bold"
        mb="2"
      >
        Hear from Stepful Alumni
      </Text>
      <Box display="flex" justifyContent="center">
        <TrustpilotRating />
      </Box>
      <Box mt="4">
        <Carousel
          slides={images.map((img, i) => (
            <Image
              key={i}
              src={img}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                WebkitTouchCallout: 'none',
                pointerEvents: 'none',
              }}
            />
          ))}
        />
      </Box>
    </Box>
  );
};

const AlumniCarousel: React.FC = () => {
  const images = [
    alumni1,
    alumni14,
    alumni2,
    alumni3,
    alumni15,
    alumni4,
    alumni5,
    alumni6,
    alumni16,
    alumni7,
    alumni8,
    alumni9,
    alumni17,
    alumni10,
    alumni11,
    alumni12,
    alumni13,
  ];

  return (
    <Box
      pt={6}
      pb={{ base: 24, md: 10 }}
      px="2"
      bg="gray.100"
      id="alumni-carousel"
    >
      <Text
        textAlign="center"
        fontSize={{ base: 'md', md: '2xl' }}
        fontWeight="bold"
        mb="2"
      >
        Join Our 7,000+ Successful Alumni
      </Text>
      <Box display="flex" justifyContent="center">
        <TrustpilotRating />
      </Box>
      <Box mt="4">
        <Carousel
          customStyle={{
            height: '100%',
          }}
          carouselProps={{
            speed: 4500,
            breakpoints: {
              576: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 5,
              },
              1536: {
                slidesPerView: 7,
              },
            },
            autoplay: {
              delay: 1,
            },
            freeMode: {
              enabled: true,
              momentum: false,
            },
            slidesPerView: 2,
          }}
          slides={images.map((img, i) => (
            <Image
              key={i}
              src={img}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                WebkitTouchCallout: 'none',
                pointerEvents: 'none',
                borderRadius: '10px',
              }}
              width="100%"
            />
          ))}
        />
      </Box>
    </Box>
  );
};

const ViewBottomContentButton: React.FC = () => (
  <GlowingEffect rounded="full">
    <Button
      display="flex"
      backgroundColor="gray.100"
      borderRadius="lg"
      px="2"
      height="6"
      alignItems="center"
      width="fit-content"
      onClick={() => {
        document
          .getElementById('alumni-carousel')
          ?.scrollIntoView({ behavior: 'smooth' });
      }}
      _hover={{ backgroundColor: 'gray.200' }}
    >
      <Icon as={HeartIcon} mr="1" />
      <Text fontSize="xs" fontWeight="500">
        Tap To See Our Students&apos; Success Stories
      </Text>
    </Button>
  </GlowingEffect>
);

type PaymentPageVariants = {
  isSocialProofOnPaymentPage?: boolean;
  isAlumniGalleryPaymentPage?: boolean;
  isCohortAndPaymentPageImprovements?: boolean;
};

const TopContent: React.FC<PaymentPageVariants> = memo(
  ({
    isSocialProofOnPaymentPage,
    isAlumniGalleryPaymentPage,
    isCohortAndPaymentPageImprovements,
  }) => {
    if (isSocialProofOnPaymentPage || isAlumniGalleryPaymentPage) {
      return <ViewBottomContentButton />;
    }

    if (isCohortAndPaymentPageImprovements) {
      return <TrustpilotRating />;
    }

    return null;
  },
);

const BottomContent: React.FC<PaymentPageVariants> = memo(
  ({
    isSocialProofOnPaymentPage,
    isAlumniGalleryPaymentPage,
    isCohortAndPaymentPageImprovements,
  }) => {
    if (isCohortAndPaymentPageImprovements) {
      return <SocialProof />;
    }

    if (isSocialProofOnPaymentPage) {
      return <SocialProofWithAlumniCarousel />;
    }

    if (isAlumniGalleryPaymentPage) {
      return <AlumniCarousel />;
    }

    return null;
  },
);
export const SelectPaymentPlan: React.FC = () => {
  const {
    curriculum: { short_name, num_months, requires_externships } = {},
    payment_plans: paymentPlans,
    is_social_proof_on_payment_page_variant: isSocialProofOnPaymentPageVariant,
    is_alumni_gallery_payment_page_variant: isAlumniGalleryPaymentPageVariant,
    is_cohort_and_payment_page_improvements_variant:
      isCohortAndPaymentPageImprovementsVariant,
  } = useSignupFlow();
  if (!paymentPlans?.length) throw new Error('No payment plans found');
  const form = useForm({
    defaultValues: {
      payment_plan_id: paymentPlans[0].id,
    },
  });

  const paymentPlanId = useWatch({
    control: form.control,
    name: 'payment_plan_id',
  });
  const selectedPlan = paymentPlans.find((pp) => pp.id === paymentPlanId);

  return (
    <Box>
      <SelectPaymentPlanCountdownBanner />
      <Box
        mx="auto"
        maxW="container.sm"
        px="4"
        pt="10"
        pb={isSocialProofOnPaymentPageVariant ? { base: '0', md: '12' } : '12'}
      >
        <Box>
          <TopContent
            isSocialProofOnPaymentPage={isSocialProofOnPaymentPageVariant}
            isAlumniGalleryPaymentPage={isAlumniGalleryPaymentPageVariant}
            isCohortAndPaymentPageImprovements={
              isCohortAndPaymentPageImprovementsVariant
            }
          />
          <Text
            fontSize={{ base: 'xl', md: '2xl' }}
            lineHeight="short"
            fontWeight="600"
            mb="1"
            mt="2"
          >
            Online {short_name} Course
          </Text>

          <Text color="gray.600" fontWeight="400" fontSize="sm">
            {num_months} Month 100% Online Certification Program
            {requires_externships ? (
              <>
                <br />
                In-Person Externship Included
              </>
            ) : null}
          </Text>
        </Box>

        <Box
          py={
            isSocialProofOnPaymentPageVariant ||
            isAlumniGalleryPaymentPageVariant
              ? { base: '4', md: '8' }
              : '8'
          }
        >
          <Text fontWeight="bold" fontSize="sm" color="gray.600" mb="4">
            Payment Plans
          </Text>

          <BigButtonSelect
            name="payment_plan"
            value={paymentPlanId}
            onChange={(val) => form.setValue('payment_plan_id', val!)}
            options={paymentPlans.map((pp) => ({
              key: pp.id,
              label: <PaymentPlanOption paymentPlan={pp} />,
            }))}
            targetProps={{
              position: 'relative',
            }}
          />
        </Box>

        <Box
          position={{ base: 'fixed', md: 'static' }}
          shadow={{ base: 'dark-lg', md: 'none' }}
          bottom={0}
          left={0}
          w="full"
          bg="white"
          p={{ base: '4', md: 0 }}
          zIndex={{ base: 1, md: 'auto' }}
        >
          <SignupPaymentDrawer paymentPlan={selectedPlan} />
        </Box>
        <ScheduleACall />
      </Box>

      <BottomContent
        isSocialProofOnPaymentPage={isSocialProofOnPaymentPageVariant}
        isAlumniGalleryPaymentPage={isAlumniGalleryPaymentPageVariant}
        isCohortAndPaymentPageImprovements={
          isCohortAndPaymentPageImprovementsVariant
        }
      />
    </Box>
  );
};
