import { useEffect, useId, useState } from 'react';

import { createProvider } from '~/lib/providerHelpers';

// This provider is a hack to allow us to override the lesson nav
// from anywhere. Specifically, we need to pass data from *within*
// the lesson-type component (e.g. `<Quiz/>`) to the `<LessonPage/>` footer component.

export type LessonNavOverride =
  | 'hide_nav' // hide the nav
  | 'disable_next' // force disable the Next button
  | 'enable_next' // force enable the Next button. clicking it will mark the lesson as complete.
  | 'noop_next'; // force enable the Next button. clicking it will do nothing besides go to the next lesson.

const [LessonPageProvider, useLessonPage] = createProvider(() => {
  const [navOverrides, setNavOverrides] = useState<
    {
      key: string;
      override: LessonNavOverride;
    }[]
  >([]);

  const navOverride = navOverrides[0]?.override ?? null;

  return { navOverride, setNavOverrides };
});

export { LessonPageProvider };

export const useLessonNavOverride = () => useLessonPage().navOverride;

export const useOverrideLessonNav = (override: LessonNavOverride | null) => {
  const { setNavOverrides } = useLessonPage();

  const uniqueId = useId();
  useEffect(() => {
    if (!override) return;
    // Add the override to the beginning of the array. The first element is the only one that's used.
    // Using an array prevents bugs when multiple components try to override the nav.
    setNavOverrides((prev) => [{ key: uniqueId, override }, ...prev]);
    return () =>
      setNavOverrides((prev) => prev.filter((d) => d.key !== uniqueId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [override]);
};
