import {
  Button,
  chakra,
  Link as CLink,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ShadowContentBox } from '~/bundles/Classroom/shared';
import { ROUTES } from '~/bundles/Signup/routes';
import { Link, Router } from '~/components/Link';
import { type RequestError, request } from '~/lib/request';

import { AuthLayout } from './layout';

const newPassword = async (email: string) => {
  await request({
    url: '/users/password',
    method: 'POST',
    body: {
      user: {
        email,
      },
    },
  });
};

const PasswordForm: React.FC = () => {
  const form = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const { email: emailError } = form.formState.errors;

  return (
    <chakra.form
      onSubmit={form.handleSubmit(async (values) => {
        setLoading(true);

        try {
          await newPassword(values.email);

          toast({
            title: 'Password reset instructions sent. Please check your email.',
            status: 'success',
          });

          Router.push(ROUTES.sign_in.path());
        } catch (err) {
          toast({
            title: (err as RequestError).message,
            status: 'error',
          });
        }
        setLoading(false);
      })}
      mx="auto"
      maxW="md"
      mb="10"
      mt="24"
    >
      <Text mb="8" fontWeight="bold" fontSize="3xl" textAlign="center">
        Forgot your password?
      </Text>

      <ShadowContentBox>
        <FormControl mb="4" isInvalid={!!emailError}>
          <Input
            {...form.register('email', {
              required: 'Required',
            })}
            type="email"
            placeholder="Email address"
            autoComplete="email"
          />

          <FormErrorMessage>{emailError?.message}</FormErrorMessage>
        </FormControl>

        <Button
          w="full"
          colorScheme="brand"
          type="submit"
          isLoading={loading}
          isDisabled={loading}
        >
          Send me reset password instructions
        </Button>
      </ShadowContentBox>

      <Text textAlign="center" color="gray.600" mt="6">
        or{' '}
        <CLink as={Link} href={ROUTES.sign_in.path()} color="brand.500">
          Sign in here
        </CLink>
      </Text>
    </chakra.form>
  );
};

export const NewPasswordPage: React.FC = () => {
  return <AuthLayout child={PasswordForm} />;
};
