import { getHost } from '~/lib/env';

/** `new URL` can throw, so we wrap it in a try/catch. returns `null` if invalid. */
export const parseUrl = (url: string, base = getHost()) => {
  try {
    const parsed = url ? new URL(url, base) : null;
    // if invalid, pathname will be empty
    if (parsed?.pathname) return parsed;
  } catch {}
  return null;
};

export const isUrl = (val: string) => {
  if (!/^https?:\/\/\w/.test(val)) return false;
  try {
    const url = new URL(val);
    return !!(url.pathname && url.protocol && url.host);
  } catch {}
  return false;
};

export type InputQueryObj = Record<
  string,
  string | number | undefined | null | string[] | number[] | boolean
>;
export type OutputQueryObj = Record<string, string | string[] | undefined>;

export const encodeQuery = (
  query: InputQueryObj,
  withQueryPrefix = false,
): string => {
  const params = new URLSearchParams();

  for (const key in query) {
    const value = query[key];
    if (typeof value === 'boolean') params.append(key, value ? 'true' : '');
    else if (Array.isArray(value)) {
      for (const v of value) {
        params.append(`${key}[]`, v.toString());
      }
    } else if (value != null) params.append(key, value.toString());
  }

  const joined = params.toString();

  return withQueryPrefix && joined.length > 0 ? `?${joined}` : joined;
};

export const decodeQuery = <Q extends OutputQueryObj>(str = ''): Q => {
  const start = str.indexOf('?');
  if (start >= 0) str = str.substring(start + 1);

  if (/^(https?:\/\/|\/)/.test(str)) return {} as Q;

  const params = new URLSearchParams(str);

  const obj: OutputQueryObj = {};
  params.forEach((v, k) => {
    if (k.endsWith('[]')) {
      k = k.substring(0, k.length - 2);
      if (!Array.isArray(obj[k])) obj[k] ||= [];
      (obj[k] as string[]).push(v);
    } else obj[k] = v;
  });

  return obj as Q;
};

const PLACEHOLDER_BASE = 'http://a.b';
export const addUrlQuery = (url: string, query: InputQueryObj) => {
  const uri = new URL(url, PLACEHOLDER_BASE);

  for (const key in query) {
    const value = query[key];
    if (value != null) uri.searchParams.set(key, value.toString());
  }

  if (uri.origin === PLACEHOLDER_BASE)
    return uri.pathname + uri.search + uri.hash;
  else return uri.href;
};
