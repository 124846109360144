import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

export const PageAlerts: React.FC<{
  alert: string | null;
  notice: string | null;
}> = ({ alert, notice }) => {
  const toast = useToast();

  useEffect(() => {
    if (alert)
      toast({
        title: alert,
        status: 'error',
      });
  }, [toast, alert]);
  useEffect(() => {
    if (notice)
      toast({
        title: notice,
        status: 'info',
      });
  }, [toast, notice]);

  return null;
};
