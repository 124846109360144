import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash-es';
import { useEffect, useId, useRef, useState } from 'react';

import { Router } from '~/components/Link';
import { TextLogo } from '~/components/Logo';
import { Markdown } from '~/components/Markdown';
import { request } from '~/lib/request';

export type Notification = {
  id: RecordId;
  slug: string;
  title: string | null;
  body: string | null;
  ui_region: string;
  alert_level: string | null;
  sent_at: TimestampString;
  read_at: TimestampString | null;
  extra: {
    url?: string;
    cta?: string;
    dismiss_cta?: string;
    image?: string;
    anchor?: string;
  } | null;
};

export type NotificationsData = {
  notifications: Notification[];
};

// move "read" notifications into "Archived" tab after 2 days
const ARCHIVE_AFTER_READ_AT_MILLIS = 1000 * 60 * 60 * 24 * 2;

export const useNotifications = (uiRegion?: string) => {
  const queryKey = ['/notifications'];
  const { data } = useQuery<NotificationsData>({
    queryKey,
  });

  const queryClient = useQueryClient();
  const updateData = (newData: NotificationsData) => {
    queryClient.setQueryData<NotificationsData>(queryKey, newData);
    return newData;
  };

  let notifications = data?.notifications || [];
  if (uiRegion)
    notifications = notifications.filter((n) => n.ui_region === uiRegion);

  const now = useRef(Date.now()); // prevents recalculating `archived` on every render

  const [inbox, archived] = _.partition(
    notifications,
    (n) =>
      !n.read_at ||
      Date.parse(n.read_at) + ARCHIVE_AFTER_READ_AT_MILLIS > now.current,
  );

  return {
    isLoading: data === undefined,
    notifications,
    inbox,
    archived,
    markAsRead: async (notifId?: RecordId | RecordId[]) => {
      notifId ||= notifications.map((n) => n.id);
      const ids = Array.isArray(notifId) ? notifId : [notifId];

      // ignore already read notifications
      const unread = (data?.notifications || []).filter(
        (n) => !n.read_at && ids.includes(n.id),
      );
      if (unread.length === 0) return;

      updateData(
        await request({
          url: `/notifications/read`,
          body: { ids: unread.map((n) => n.id) },
          method: 'POST',
        }),
      );
    },
    markAsClicked: async (notifId?: RecordId | RecordId[]) => {
      notifId ||= notifications.map((n) => n.id);
      const ids = Array.isArray(notifId) ? notifId : [notifId];

      await request({
        url: `/notifications/click`,
        body: { ids },
        method: 'POST',
      });
    },

    dismiss: async (notifId?: RecordId | RecordId[]) => {
      notifId ||= notifications.map((n) => n.id);
      const ids = Array.isArray(notifId) ? notifId : [notifId];
      if (ids.length === 0) return;

      updateData(
        await request({
          url: `/notifications/dismiss`,
          body: { ids },
          method: 'POST',
        }),
      );
    },
  };
};

export const useNotification = (uiRegion: string) => {
  const { notifications, dismiss, markAsRead, markAsClicked } =
    useNotifications(uiRegion);

  const notif = notifications[0];
  if (!notif) return null;

  return {
    ...notif,
    markAsRead: async () => {
      await markAsRead(notif.id);
    },
    markAsClicked: async () => {
      await markAsClicked(notif.id);
    },
    dismiss: async () => {
      await dismiss(notif.id);
    },
  };
};

export const NotifTooltip: React.FC<{
  children: JSX.Element;
  region: string;
  closeButton?: boolean;
}> = ({ region, children, closeButton = false }) => {
  const notif = useNotification(region);
  // this component must have a `title`
  if (!notif?.title) return children;

  // TODO: move to global theme
  const variant =
    notif.alert_level === 'alert'
      ? {
          bg: 'red.50',
          color: 'red.600',
        }
      : notif.alert_level === 'info'
      ? {
          bg: 'blue.50',
          color: 'blue.600',
        }
      : {};

  return (
    <Popover
      key={notif.id}
      onClose={notif.dismiss}
      // only trigger `onClose` when user clicks the `PopoverTrigger`
      closeOnBlur={false}
      closeOnEsc={false}
      defaultIsOpen
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent {...variant}>
        <PopoverArrow {...variant} />
        {closeButton && <PopoverCloseButton />}
        <PopoverHeader fontWeight="semibold">{notif.title}</PopoverHeader>
        {!!notif.body && <PopoverBody>{notif.body}</PopoverBody>}
      </PopoverContent>
    </Popover>
  );
};

// TODO: use the `useModalManager` hook instead (released in chakra-ui v2.7)
const useAnyOtherActiveModals = (exceptId: string) => {
  const [others, setOthers] = useState<boolean | null>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setOthers(
        !!document.querySelector(
          `.chakra-modal__content:not(#chakra-modal-${CSS.escape(exceptId)})`,
        ),
      );
    });
    observer.observe(document.body, { childList: true });
    return () => observer.disconnect();
  }, [exceptId]);

  return others;
};

export const NotificationModal: React.FC = () => {
  const notif = useNotification('modal');

  const modalId = useId();
  const anyOtherActiveModals = useAnyOtherActiveModals(modalId);

  const showing =
    // `anyOtherActiveModals` is `null` until the observer is initialized
    anyOtherActiveModals === false &&
    // modal notifications must have a `body`
    !!notif?.body;

  const notifId = notif?.id ?? null;
  const latestMarkAsRead = useRef(notif?.markAsRead);
  latestMarkAsRead.current = notif?.markAsRead;
  // when the modal is showing, trigger markAsRead when notifId changes
  useEffect(() => {
    if (notifId && showing) void latestMarkAsRead.current?.();
  }, [notifId, showing]);

  if (!showing || !notif?.body) return null;

  return (
    <Modal id={modalId} onClose={notif.dismiss} isOpen size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <TextLogo mr="4" />
          {notif.title ? <Markdown inline>{notif.title}</Markdown> : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          {notif.extra?.image && typeof notif.extra.image === 'string' ? (
            <Box textAlign="center" my="4">
              <Image
                borderRadius="full"
                margin="auto"
                boxSize="150px"
                src={notif.extra?.image}
                alt="Extra image"
              />
            </Box>
          ) : null}
          <Markdown>{notif.body}</Markdown>
          {notif.extra?.url && typeof notif.extra.url === 'string' ? (
            <Box textAlign="center" mt="4">
              <Button
                width="full"
                onClick={async () => {
                  try {
                    await notif.markAsClicked();
                    await notif.dismiss();
                  } catch {}
                  Router.push(notif.extra!.url!);
                }}
                colorScheme="brand"
              >
                {(typeof notif.extra.cta === 'string' && notif.extra.cta) ||
                  'Learn more'}
              </Button>
            </Box>
          ) : null}
          {notif.extra?.dismiss_cta &&
          typeof notif.extra.dismiss_cta === 'string' ? (
            <Box textAlign="center" mt="4">
              <Button
                width="full"
                variant="link"
                onClick={async () => {
                  try {
                    await notif.dismiss();
                  } catch {}
                }}
                colorScheme="brand"
              >
                {notif.extra?.dismiss_cta}
              </Button>
            </Box>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
