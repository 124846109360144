import { Box } from '@chakra-ui/react';
import {
  type DehydratedState,
  type QueryFunctionContext,
  HydrationBoundary,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createContext, useContext, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { ErrorBoundary } from '~/components/ErrorBoundary';
import { ErrorPage } from '~/components/ErrorPage';
import { useLocation } from '~/components/Link';
import { LoadingShimmer } from '~/components/LoadingShimmer';
import { type RequestError, request } from '~/lib/request';
import { addUrlQuery } from '~/lib/utils';
import { buildHydrateState } from '~/lib/utils/react-query';

const emptyCtx = Symbol('emptyCtx');

type PageDataValue<Data> =
  | {
      data: Data;
      url: string;
      refresh: () => Promise<Data | undefined>;
      updateData: (newData: Data | ((prev: Data | undefined) => Data)) => Data;
    }
  | typeof emptyCtx;

export const PageDataCtx = createContext<PageDataValue<any>>(emptyCtx);

const RootDataCtx = createContext<Record<string, unknown> | typeof emptyCtx>(
  emptyCtx,
);

export const useRootData = <Data,>() => {
  const ctx = useContext(RootDataCtx);
  if (ctx === emptyCtx)
    throw new Error('useRootData must be used within a PageDataRoot');
  return ctx as Data;
};

export const PageDataRoot: React.FC<{
  rootProps: Record<string, any>;
  children: React.ReactNode;
}> = ({ rootProps, children }) => {
  // prefill react-query data for the current page, ONLY during the initial render (SSR)
  const location = useLocation();
  const queryKey = [location.pathname + location.search];
  const queryHash = JSON.stringify(queryKey);
  const pageFallbackData = useRef<DehydratedState>();
  const queryClient = useQueryClient();
  pageFallbackData.current ??=
    // don't overwrite existing fallback data
    queryClient.getQueryCache().get(queryHash)
      ? undefined
      : buildHydrateState([queryKey, rootProps]);

  return (
    <HydrationBoundary
      // this is merged with any parent `<HydrationBoundary/>` queries
      state={pageFallbackData.current}
    >
      <RootDataCtx.Provider value={rootProps}>{children}</RootDataCtx.Provider>
    </HydrationBoundary>
  );
};

export const loadingPage = (
  <Box py="32" px="16" mx="auto" w="full" maxW="1200px">
    <LoadingShimmer />
  </Box>
);

const pagePropsFetcher = <Res,>({ queryKey: [url] }: QueryFunctionContext) =>
  request<Res>({
    // TODO: should we add file extension (.json) instead for better APM tracing?
    url: addUrlQuery(url as string, { format: 'json' }),
  });

const PageDataContent: React.FC<{
  children: React.ReactNode;
  url: string;
  /** If true, don't update the url in the `useSWR` hook */
  isShallow?: boolean;
  /** Show the previous url's data if the new url is loading and has the same route pattern */
  withFallback?: boolean;
}> = ({ url: nextUrl, isShallow, children, withFallback = true }) => {
  // the reason we have to wrap **each** page in a `PageDataRoot` is because
  // of the following `useRouteMatch`, which must be within in `<Route/>`
  const currentRoutePattern = useRouteMatch()?.path;

  // only update `url` if `!isShallow`
  const urlRef = useRef(nextUrl);
  if (urlRef.current !== nextUrl && !isShallow) {
    urlRef.current = nextUrl;
  }
  const url = urlRef.current;

  const prevRoute = useRef<{
    data: unknown;
    routePattern: string;
  } | null>(null);
  if (!withFallback) prevRoute.current = null;

  const { data, error, refetch } = useQuery<unknown, RequestError>({
    queryFn: pagePropsFetcher,
    queryKey: [url],
    // prevent revalidation when the data is already in the cache on page load
    refetchOnMount: false,
  });

  const queryClient = useQueryClient();

  // use the previous page's data as fallbackData to prevent the loading indicator
  // when a page reloads b/c one of it's params changed.
  // the route **pattern** must be the same e.g. '/foo/:id', or else the data won't be valid for that page.
  const showPreviousData =
    data === undefined &&
    !!prevRoute.current &&
    prevRoute.current.routePattern === currentRoutePattern
      ? prevRoute.current.data
      : undefined;

  // populate `prevRoute` with the current route pattern (after page finishes loading)
  if (withFallback && data !== undefined)
    prevRoute.current = {
      routePattern: currentRoutePattern,
      data,
    };

  if (error) {
    prevRoute.current = null;
    throw error;
  }

  const shownData = showPreviousData !== undefined ? showPreviousData : data;

  if (shownData === undefined) return loadingPage;

  return (
    <PageDataCtx.Provider
      value={{
        data: shownData,
        url,
        refresh: async () => {
          return (await refetch()).data;
        },
        updateData: (newData) => {
          queryClient.setQueryData([url], newData);
          return newData;
        },
      }}
    >
      {children}
    </PageDataCtx.Provider>
  );
};

export const PageDataLoader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const url = location.pathname + location.search;

  return (
    <ErrorBoundary fallback={ErrorPage}>
      <PageDataContent url={url} isShallow={!!location.state?.shallow}>
        {children}
      </PageDataContent>
    </ErrorBoundary>
  );
};

export const usePageData = <PageData,>() => {
  const ctx = useContext<PageDataValue<PageData>>(PageDataCtx);
  if (ctx === emptyCtx) throw new Error('Missing PageDataLoader parent');
  const { data, url } = ctx;
  if (data == null) throw new Error(`Missing page data: ${url}`);
  return data;
};

export const usePageDataContext = <PageData,>() => {
  const ctx = useContext<PageDataValue<PageData>>(PageDataCtx);
  if (ctx === emptyCtx) throw new Error('Missing PageDataLoader parent');
  if (ctx.data == null) throw new Error(`Missing page data: ${ctx.url}`);
  return ctx;
};

export const usePageParams = useParams;
