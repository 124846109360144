// random utils. prefer using separate files

import { parseISO } from 'date-fns';
import * as _ from 'lodash-es';

export * from './url'; // legacy

type CompactObj = {
  <Obj extends Record<string, any>>(obj: Obj): {
    [P in keyof Obj]: Exclude<Obj[P], null | undefined>;
  };
  <Obj extends any[]>(obj: Obj): NonNullable<Obj[number]>[];
};
export const compactObj = ((obj: any) => {
  if (Array.isArray(obj)) {
    return obj.filter((v) => v != null);
  } else {
    return _.transform(
      obj,
      (acc, val, key) => {
        if (val != null) acc[key as string] = val;
      },
      {} as Record<string, any>,
    );
  }
}) as CompactObj;

export type TimeToMillisInput = number | string | Date;

export const timeToMillis = (time: TimeToMillisInput): number | null => {
  if (time == null) return null;

  if (typeof time === 'string') time = parseISO(time).getTime();
  else if (time instanceof Date) time = time.getTime();

  return typeof time === 'number' && !Number.isNaN(time) && time >= 0
    ? Math.floor(time)
    : null;
};

export const parseNum = (val: any): number | null => {
  if (typeof val === 'string' && val.length > 0) val = Number.parseFloat(val);
  if (typeof val === 'number' && !Number.isNaN(val)) return val;
  return null;
};

export const parseInt = (val: any): number | null => {
  if (typeof val === 'string' && val.length > 0) val = Number.parseInt(val, 10);
  if (typeof val === 'number' && !Number.isNaN(val)) return Math.floor(val);
  return null;
};

export const waitFor = (
  check: () => boolean,
  timeout: number,
  interval = Math.ceil(timeout / 5),
) =>
  new Promise<boolean>((resolve) => {
    let inter: ReturnType<typeof setInterval> | undefined;

    const t = setTimeout(() => {
      if (inter) clearInterval(inter);
      resolve(false);
    }, timeout);

    inter = setInterval(() => {
      if (check()) {
        if (inter) clearInterval(inter);
        clearTimeout(t);
        resolve(true);
      }
    }, interval);
  });

export const safeStorage = {
  set: (key: string, value: string | null) => {
    try {
      if (value == null) localStorage.removeItem(key);
      else localStorage.setItem(key, value);
    } catch {}
  },
  get: (key: string): string | null => {
    try {
      return localStorage.getItem(key);
    } catch {}
    return null;
  },
};

export const assertNonEmpty = <T>(array: T[]) => {
  return array as [T, ...T[]];
};

/**
 * Like lodash's `_.pick`, but with type safety.
 */
export const pick = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[],
) => {
  const newObj = {} as Pick<T, K>;
  for (const key of keys) {
    newObj[key] = obj[key];
  }
  return newObj;
};
