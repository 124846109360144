import { type ButtonProps, Button, chakra } from '@chakra-ui/react';
import { BiPieChart as ContinueIcon } from '@react-icons/all-files/bi/BiPieChart';
import { FaCheckCircle as CompletedIcon } from '@react-icons/all-files/fa/FaCheckCircle';
import { FaChevronCircleRight as StartIcon } from '@react-icons/all-files/fa/FaChevronCircleRight';

import { Link } from '~/components/Link';

const ICONS = {
  start: StartIcon,
  completed: CompletedIcon,
  continue: ContinueIcon,
};

/**
 * A commonly used styled box with some shadow
 */
export const ShadowContentBox = chakra('div', {
  baseStyle: {
    p: '4',
    rounded: 'lg',
    borderWidth: '1px',
    borderColor: 'gray.300',
    shadow: 'base',
  },
});

export const ContinueButton: React.FC<
  ButtonProps & {
    icon?: keyof typeof ICONS;
    isLoading?: boolean;
    isHighlighted?: boolean;
    isMuted?: boolean;
    href?: string;
    newTab?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }
> = ({
  onClick,
  href,
  newTab,
  icon,
  isLoading,
  isMuted,
  isHighlighted,
  children,
  ...rest
}) => {
  if (href) {
    Object.assign(rest, {
      as: Link,
      href,
      newTab,
    });
  } else if (!onClick) {
    Object.assign(rest, { as: 'span' });
  }

  const Icon = (icon && ICONS[icon]) || ICONS.start;

  return (
    <Button
      {...rest}
      onClick={onClick}
      colorScheme={isMuted ? 'gray' : 'blue'}
      variant={isHighlighted ? 'solid' : 'outline'}
      justifyContent="space-between"
      rounded="full"
      disabled={isLoading}
      isLoading={isLoading}
      pr="2"
      rightIcon={
        <Icon
          fontSize="1.5rem"
          opacity={isMuted ? 0.3 : isHighlighted ? 0.8 : 1}
        />
      }
    >
      {children}
    </Button>
  );
};

export const MIN_GRADUATION_GRADE = 80;
export const LOW_GRADUATION_GRADE = 90;
