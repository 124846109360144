import type { SeverityLevel } from '@sentry/react';
import * as SentryBrowser from '@sentry/react';

import { DEPLOY_ENV, FRONTEND_SENTRY_DSN } from '~/lib/env';
import { LOADED_RELEASE_VERSION } from '~/lib/releaseManager';
import { RequestError } from '~/lib/request';

// `SentryBrowser` may be undefined if webpack excludes it from the bundle
const Sentry = typeof window === 'undefined' ? null : SentryBrowser;

export const initSentry = () => {
  if (!Sentry) return;

  Sentry.init({
    dsn: FRONTEND_SENTRY_DSN,
    environment: DEPLOY_ENV,
    release: LOADED_RELEASE_VERSION,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5,
    integrations: [
      SentryBrowser.replayIntegration({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
      }),
      SentryBrowser.browserTracingIntegration(),
    ],
  });
};

// make sure this matches our backend tracking context
export const setTrackingContext = (
  ctx: {
    user?: { id: RecordId; email: string; name: string; role: string } | null;
    impersonating?: boolean;
  } & Record<string, any>,
) => {
  if (!Sentry) return;

  const { user, impersonating, ...rest } = ctx;

  if (user !== undefined) {
    if (user) {
      Sentry.setUser({
        id: user.id.toString(),
        email: user.email,
        username: user.name,
      });
    } else {
      Sentry.setUser(null);
    }

    Sentry.setTags({ user_role: user?.role || 'anonymous' });
  }

  if (impersonating !== undefined) Sentry.setTags({ impersonating });

  Sentry.setExtras(rest);
};

const consoleLevels: {
  [severity in SeverityLevel]: 'error' | 'warn' | 'log' | 'debug';
} = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  info: 'log',
  log: 'log',
  debug: 'debug',
} as const;

type Details = Record<string, any>;

type TrackError = {
  (msg: string, details?: Details, level?: SeverityLevel): Promise<void>;
  (error: Error, msg: string, details?: Details): Promise<void>;
  (error: Error, details?: Details): Promise<void>;
};
export const trackError: TrackError = async (a: any, b?: any, c?: any) => {
  let msg: string | undefined,
    error: Error | undefined,
    details: Details | undefined,
    level: SeverityLevel = 'error';
  if (typeof a === 'string') {
    msg = a;
    details = b;
    if (c) level = c;
  } else if (typeof b === 'string') {
    error = a;
    msg = b;
    details = c;
  } else {
    error = a;
    details = b;
  }

  try {
    (console[consoleLevels[level]] || console.error)(
      ...[error, msg, details].filter(Boolean),
    );

    // if we're running in automated testing, throw the error as fatal
    if (navigator.webdriver) {
      throw error || new Error(msg);
    }

    if (Sentry) {
      Sentry.withScope((scope) => {
        if ((error as any)?.meta)
          scope.setExtra('error_meta', (error as any).meta);

        if (details) scope.setExtras(details);

        if (error instanceof RequestError) {
          scope.setContext('api_request', {
            method: error.method,
            url: error.url,
            statusCode: error.statusCode,
          });
        }

        if (error) {
          if (msg) scope.setTransactionName(msg);

          Sentry.captureException(error);
        } else if (msg) {
          scope.setLevel(level);
          Sentry.captureMessage(msg);
        }
      });

      // if (typeof window === 'undefined') await Sentry.flush(2000);
    }
  } catch {}
};
