// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// NOTE: this file is NOT loaded in activeadmin
// NOTE: do not include this file in server_rendering.js

import '~/lib/browserPolyfills';

import { initSentry } from '~/lib/errorTracking';
import '~/lib/htmlHelpers';

import '~/styles/application.scss';

initSentry();
