import { Box, Icon, Text, useCallbackRef } from '@chakra-ui/react';
import React from 'react';

import { lazy } from '~/components/lazy';
import { type CourseLessonSurvey, useCourseData } from '~/lib/courseData';
import { useUserData } from '~/lib/userData';
import { compactObj } from '~/lib/utils';

import { LessonContentFrame } from './components/LessonContentFrame';
import { useCurrentEnrollment } from './data';
import { CompleteIcon } from './icons';
import { useOverrideLessonNav } from './lessonPageData';

export const TypeformWidget = lazy(() =>
  import('@typeform/embed-react').then((d) => d.Widget),
);

export const SurveyLessonDumber: React.FC<{
  article: CourseLessonSurvey;
  updateProgress: () => Promise<boolean>;
}> = ({ article, updateProgress }) => {
  const user = useUserData();
  const {
    id: enrollment_id,
    cohort,
    sponsor_name_label,
    sponsor_type,
    line_of_business,
    learning_coach_email,
    curriculum: { slug: curriculum_slug },
  } = useCurrentEnrollment();

  const isCompleted = article?.status === 'completed';

  const onSubmit = useCallbackRef(
    async ({ responseId }: { responseId: string }) => {
      console.log('Survey submitted', responseId);

      void updateProgress();
    },
  );

  useOverrideLessonNav(
    article.optional || article.status === 'completed' ? 'enable_next' : null,
  );

  const title = article.name + (article.optional ? ' (optional)' : '');

  return (
    <LessonContentFrame title={title}>
      {isCompleted ? (
        <Box>
          <Text textAlign="center" py="16" fontSize="lg">
            <Icon
              as={CompleteIcon}
              w="8"
              h="8"
              color="green.400"
              display="inline-block"
            />{' '}
            Your form has been successfully submitted!
          </Text>
        </Box>
      ) : (
        <TypeformWidget
          id={article.typeform.external_id}
          style={{ height: '36rem' }}
          iframeProps={{ style: 'padding-bottom: 60px; padding-top: 30px;' }}
          hidden={compactObj({
            email: user.email,
            full_name: user.name,
            enrollment_id: enrollment_id.toString(),
            cohort: cohort.name,
            sponsor: sponsor_name_label,
            content_lesson_id: article?.content_lesson_id.toString(),
            sponsor_type,
            line_of_business,
            learning_coach_email,
            program: curriculum_slug,
            week: article.typeform.week_field.toString(),
          })}
          onSubmit={onSubmit}
        />
      )}
    </LessonContentFrame>
  );
};

export const SurveyLesson: React.FC<{
  article: CourseLessonSurvey;
}> = ({ article }) => {
  const { updateProgress } = useCourseData();

  return (
    <SurveyLessonDumber article={article} updateProgress={updateProgress} />
  );
};
