import { QueryClient, type QueryKey, dehydrate } from '@tanstack/react-query';

export const buildHydrateState = (
  ...datas: (readonly [queryKey: QueryKey, data: unknown])[]
) => {
  const tempClient = new QueryClient();
  for (const [queryKey, data] of datas) {
    tempClient.setQueryData(queryKey, data);
  }
  return dehydrate(tempClient);
};
