import { lazy as reactLazy, Suspense, useEffect, useState } from 'react';

export const lazy = <Comp extends React.ComponentType<any>>(
  factory: () => Promise<Comp | { default: Comp }>,
  fallback: React.ReactNode = null,
): Comp => {
  const Lazy = reactLazy(() =>
    factory().then((f) => ('default' in f ? f : { default: f })),
  );

  const SuspensedLazy = ((props) => {
    // We need to consistently render `fallback` on the first render on both the server and the client.
    // This fixes the following scenario:
    // - user visits a page for the first time
    // - SuspensedLazy renders `fallback` on the server
    // - SuspensedLazy renders `fallback` on the client. it matches what the server rendered, success!
    // - our component `Comp` is dynamically loaded, then it renders
    // - use reloads the page
    // - SuspensedLazy renders `fallback` on the server
    // - SuspensedLazy renders `Comp` on the client because it was already loaded. it doesn't match what the server rendered, failure!
    const [first, setFirst] = useState(false);
    useEffect(() => setFirst(true), []);
    if (!first) return fallback;

    return (
      <Suspense fallback={fallback}>
        <Lazy {...props} />
      </Suspense>
    );
  }) as Comp;

  return SuspensedLazy;
};
