import {
  type Day,
  addMinutes,
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInMinutes,
  isWithinInterval,
  parseISO,
  subMinutes,
} from 'date-fns';
import { getTimezoneOffset, toZonedTime } from 'date-fns-tz';

/**
 * Same as [differenceInCalendarDays](https://date-fns.org/latest/docs/differenceInCalendarDays),
 * but uses the given timezone instead of the local timezone.
 */
export const differenceInCalendarDaysInTimezone = (
  date1: Date,
  date2: Date,
  timezone: string | null,
): number => {
  if (!timezone) return differenceInCalendarDays(date2, date1);

  // Convert input dates to their respective timezones
  const zonedDate1 = toZonedTime(date1, timezone);
  const zonedDate2 = toZonedTime(date2, timezone);

  // Calculate the difference in calendar days
  return differenceInCalendarDays(zonedDate2, zonedDate1);
};

export const timezoneLabel = (
  timeZone: string,
  format: Intl.DateTimeFormatOptions['timeZoneName'] = 'longGeneric',
) => {
  try {
    return new Intl.DateTimeFormat('en-us', {
      timeZoneName: format,
      timeZone,
    })
      .formatToParts(new Date())
      .find((part) => part.type === 'timeZoneName')?.value;
  } catch {
    return undefined;
  }
};

export const dateAndTimeToTimestamp = (
  date: DateString,
  time: TimeOfDayString,
  timezone: string,
) => {
  const dateObj = new Date(`${date}T${time}Z`);
  const offset = getTimezoneOffset(timezone, dateObj);
  return new Date(dateObj.getTime() - offset);
};

export const readableMonth = (date: Date) => {
  return date.toLocaleString('en-US', {
    timeZone: 'UTC',
    month: 'long',
    year: 'numeric',
  });
};

export const readableDay = (date: Date) => {
  return date.toLocaleString('en-US', {
    timeZone: 'UTC',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

/**
 * Add the given number of days to the given ISO date string, and return the result as an ISO date string.
 * This avoid issues with Daylight Savings/timezone changes.
 */
export const addIsoDays = (date: DateString, days: number): DateString => {
  const d = parseISO(date + 'T00:00:00Z');
  d.setUTCDate(d.getUTCDate() + days);
  return d.toISOString().slice(0, 10);
};

// TODO: use `addIsoDays` internally for this function
export const getNextDayOfWeekInNWeeks = (
  date: DateString,
  numWeeks: number,
  dayOfWeek: number,
) => {
  const nextDate = new Date(`${date}T00:00`);
  nextDate.setDate(
    nextDate.getDate() +
      numWeeks * 7 +
      ((dayOfWeek + 7 - nextDate.getDay()) % 7),
  );
  return nextDate;
};

// A constant function with two datestrings as input that returns the number of weeks between two dates
export const isoWeeksBetween = (
  startDate: DateString,
  endDate: DateString,
  weekStartsOn?: Day,
) => {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  return differenceInCalendarWeeks(end, start, { weekStartsOn });
};

export const isoDaysBetween = (startDate: DateString, endDate: DateString) => {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  return differenceInCalendarDays(end, start);
};

// Takes a string like "10:00" and returns "10 AM"
export const convertTimeTo12HourFormat = (time: string): string => {
  let hour = time.split(':').map(Number)[0];
  const suffix = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  return `${hour} ${suffix}`;
};

export const formatHoursAndMinutes = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  return `${hours}h ${minutes}m`;
};

// Returns the difference in minutes between two time strings
export const timeDifferenceInMinutes = (timeStr1: string, timeStr2: string) => {
  // Define a base date to avoid date-related issues
  const baseDate = '1970-01-01';

  // Convert time strings to ISO strings with the base date
  const isoTime1 = `${baseDate}T${timeStr1}:00Z`;
  const isoTime2 = `${baseDate}T${timeStr2}:00Z`;

  // Parse the ISO strings to Date objects
  const date1 = parseISO(isoTime1);
  const date2 = parseISO(isoTime2);

  // Calculate the difference in minutes
  const differenceInMinutesValue = differenceInMinutes(date2, date1);

  return Math.abs(differenceInMinutesValue);
};

// Checks if a given date string falls within a time interval of +/- hours from now
// For example, isWithinHours("2024-01-01T10:00:00Z", 12) checks if the date is within 12 hours before or after the current time
export const isWithinHours = (date: string, hours: number): boolean => {
  return isWithinInterval(date, {
    start: subMinutes(new Date(), hours * 60),
    end: addMinutes(new Date(), hours * 60),
  });
};
