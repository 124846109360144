import { useCurrentEnrollment } from '~/bundles/Classroom/data';
import { trackError } from '~/lib/errorTracking';
import { request } from '~/lib/request';

// set globally here so we do not have to use hooks later for event tracking calls
let globalEnrollmentId: string | number | null = null;

export const useSetTrackingEnrollment = () => {
  const enrollment = useCurrentEnrollment('blank');

  globalEnrollmentId = enrollment?.id ?? null;
};

// Matches backend const: Analytics::Destination
type TrackingDestination = 'lake' | 'local';

export type TrackingProps = {
  destinations?: TrackingDestination[];
  uniqueness?: 'skip';
  enrollmentId?: RecordId;
};

export const trackEvent = (
  eventName: string,
  params?: Record<string, unknown>,
  { destinations = ['lake'], uniqueness, enrollmentId }: TrackingProps = {},
) => {
  console.log(`[trackEvent] ${eventName}`, params);

  request({
    url: '/aja/events',
    method: 'POST',
    asBeacon: true,
    body: {
      event_name: eventName,
      enrollment_id: enrollmentId ?? globalEnrollmentId,
      properties: params,
      context: {
        url: window.location.href,
      },
      destinations,
      uniqueness,
    },
  }).catch((err) => trackError(err, 'trackEvent'));
};

/**
 * Track event via Google Tag Manager
 */
export const googleTagManagerTrack = (
  eventName: string,
  params?: Record<string, unknown>,
) => {
  console.log(`[googleTagManagerTrack] ${eventName}`, params);

  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...params,
    });
  }
};

export const trackPageView = async ({
  url = window.location.href,
  referrer,
}: {
  url: string;
  referrer?: string;
}) => {
  // wait for `<Head/>` to update `document.title`
  await new Promise<void>((r) => setTimeout(r));
  const title = document.title;

  await request({
    url: '/aja/views',
    method: 'POST',
    asBeacon: true,
    body: {
      enrollment_id: globalEnrollmentId,
      referrer,
      title,
      url,
    },
  }).catch((err) => trackError(err as Error, 'trackPageView'));
};

// Hook into `window.history.pushState` and `replaceState` to track page views.
// Forked from: https://github.com/umami-software/umami/blob/master/tracker/index.js
const setupAutoPageTracking = () => {
  let currentUrl = window.location.href;
  let currentRef = document.referrer;

  const methodHook = <
    Fn extends (...args: any[]) => any,
    Method extends string,
    Base extends { [m in Method]: Fn },
  >(
    _this: Base,
    method: Method,
    callback: Fn,
  ) => {
    const orig = _this[method];

    return ((...args) => {
      callback(...args);

      return orig.apply(_this, args);
    }) as Fn;
  };

  const handlePush: typeof history.pushState = (_state, _title, url) => {
    if (!url) return;

    currentRef = currentUrl;
    currentUrl = url.toString();

    const metadata = {
      url: currentUrl,
      referrer: currentRef,
    };

    if (currentUrl !== currentRef) {
      void trackPageView(metadata);
    }
  };

  history.pushState = methodHook(history, 'pushState', handlePush);
  history.replaceState = methodHook(history, 'replaceState', handlePush);
};

const setupAutoClickTracking = () => {
  const handleEvent = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();
    const type = e.type;
    const eventName = target.getAttribute('data-event-name');
    const properties: Record<string, unknown> = JSON.parse(
      target.getAttribute('data-event-properties') ?? '{}',
    );

    if (!eventName) return;

    if (tagName === 'a' && type === 'click') {
      const href = target.getAttribute('href');
      trackEvent(eventName, {
        href,
        text: target.innerText,
        ...properties,
      });
    } else if (tagName === 'button' && type === 'click') {
      trackEvent(eventName, {
        text: target.innerText,
        ...properties,
      });
    }
  };

  document.addEventListener('click', handleEvent, true);
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    init_auto_page_tracking?: boolean;
    dataLayer?: unknown[];
  }
}

export const setupAutoTracking = () => {
  try {
    if (typeof window !== 'undefined' && !window.init_auto_page_tracking) {
      window.init_auto_page_tracking = true;
      setupAutoPageTracking();
      setupAutoClickTracking();
    }
  } catch {}
};
