import * as _ from 'lodash-es';

import {
  buildRoute,
  routePrefixFactory,
  useRouteParams,
} from '~/lib/routeBuilder';

const courseRoute = routePrefixFactory('/courses/:course_slug');

export const ROUTES = {
  profile: buildRoute('/profile'),

  verify_email: buildRoute('/profile/verify-email'),

  edit_email: buildRoute('/profile/edit-email'),

  course_root: courseRoute(''),

  onboarding: courseRoute('/onboarding'),

  career_portal: courseRoute('/career-services'),

  progress_page: courseRoute('/progress'),

  job_search_form: courseRoute('/job-search-form/:jsf_deadline_date'),

  lesson: courseRoute([
    '/lesson/:scheduled_lesson_id',
    '/lesson/:scheduled_lesson_id/:label_for_url', // label_for_url is purely for aesthetics
  ] as const),

  forgiveness: courseRoute('/forgiveness'),
};

const COURSE_ROUTES = [
  ROUTES.course_root,
  ROUTES.career_portal,
  ROUTES.lesson,
  ROUTES.progress_page,
  ROUTES.forgiveness,
] as const;
const NON_COURSE_ROUTES = _.difference(Object.values(ROUTES), COURSE_ROUTES);

/**
 * Returns matched route params for `COURSE_ROUTES`, excluding `NON_COURSE_ROUTES`.
 * We need to check for `NON_COURSE_ROUTES` because `course_root` route matches
 * any route that starts with `/courses/:course_slug/`.
 */
export const useCourseContentRoutesParams = () => {
  const courseParams = useRouteParams(COURSE_ROUTES);

  const isNonCourseMatch = !!useRouteParams(NON_COURSE_ROUTES, { exact: true });

  return (!isNonCourseMatch && courseParams) || null;
};
