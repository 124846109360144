import { type ImgProps, Img } from '@chakra-ui/react';

import textLogoImgUrl from '../../assets/images/stepful-logo.png';

export const TextLogo: React.FC<ImgProps> = (props) => {
  return (
    <Img
      src={textLogoImgUrl}
      h="22px" // default height
      display="inline-block"
      lineHeight={1}
      alt="Stepful"
      {...props}
    />
  );
};
