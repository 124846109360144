import { type QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import type { Enrollment } from '~/bundles/Classroom/types';
import { request } from '~/lib/request';
import type { UserFeatureFlags } from '~/queries/feature_flags';

export type PermissionKey =
  | 'account_manager'
  | 'externship_coordinator'
  | 'coach'
  | 'resume_editor'
  | 'instructor'
  | 'admin'
  | 'student';

type BackendUserData = {
  id: RecordId;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  unconfirmed_email: string | null;
  permissions: PermissionKey[];
  role: string;
  display_phlebotomy_banner: boolean;
  feature_flags: UserFeatureFlags;
};

export type BackendResponseData = {
  user: BackendUserData | null;
  enrollments: Enrollment[];
};

export type UserData = BackendUserData & {
  [key in `is_${PermissionKey}`]?: boolean;
};

const USER_API_URL = '/user';
export const USER_QUERY_KEY: QueryKey = [USER_API_URL]; // hydrated by the server

export const fetchUser = () =>
  request<BackendResponseData>({ url: USER_API_URL });

const useUserApiData = () => {
  return useQuery({
    queryKey: USER_QUERY_KEY,
    queryFn: fetchUser,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useRefetchUser = () => {
  return useUserApiData().refetch;
};
export const useUpdateUserData = () => {
  const queryClient = useQueryClient();
  return (data: BackendResponseData) => {
    queryClient.setQueryData(USER_QUERY_KEY, data);
  };
};

/**
 * The user's enrollments, ordered by "relevance".
 */
export const useEnrollments = () => useUserApiData().data?.enrollments;

/**
 * Loads and returns the current user's data.
 * `undefined` while loading, `null` if not authenticated.
 * Provide the `optional` parameter prevents throwing an error if the user is not loaded/authenticated.
 */
export const useUserData = <Optional extends boolean | undefined = undefined>(
  optional?: Optional,
) => {
  type Return = Optional extends true ? UserData | null | undefined : UserData;

  const { user: apiUser } = useUserApiData().data || {};

  const transformedUser = useMemo(() => {
    if (!apiUser) return apiUser;

    const user: UserData = { ...apiUser };

    for (const perm of user.permissions) user[`is_${perm}`] = true;

    return user;
  }, [apiUser]);

  if (!optional && !transformedUser)
    throw new Error('Accessing unauthenticated user data');

  return transformedUser as Return;
};
