import { parseISO } from 'date-fns';
import * as _ from 'lodash-es';

import { useRouteParams } from '~/lib/routeBuilder';
import { useEnrollments } from '~/lib/userData';

import { ROUTES } from '../routes';
import type { Enrollment } from '../types';

/**
 * @param fallback - if missing current enrollment:
 * - undefined: throw an error
 * - "blank": fallback to: `null`
 * - "payments": fallback to: most relevant enrollment for payments, or `null`
 * - "careers": only for enrollments that have access to the career-portal: find the current enrollment, or fallback to the most relevant enrollment, or `null`
 * - "relevant": fallback to: "most relevant" enrollment, or `null`
 */
export const useCurrentEnrollment = <
  Fallback extends
    | undefined
    | 'blank'
    | 'payments'
    | 'careers'
    | 'relevant' = undefined,
>(
  fallback?: Fallback,
) => {
  type Return = Fallback extends 'blank' | 'relevant' | 'careers' | 'payments'
    ? Enrollment | null
    : Enrollment;

  const courseSlug = useRouteParams(ROUTES.course_root)?.course_slug;

  const enrollments = useEnrollments() || [];

  if (fallback === 'careers') {
    const canAccess = enrollments.filter((enr) => enr.can_access_career_portal);

    let current = courseSlug
      ? canAccess.find((enr) => enr.cohort.slug === courseSlug)
      : null;

    current ||= canAccess[0]; // most relevant career-accessible enrollment

    return (current || null) as Return;
  }

  const current = courseSlug
    ? enrollments.find((enr) => enr.cohort.slug === courseSlug)
    : null;
  if (current) return current as Return;

  // handle fallback scenarios:

  if (fallback === 'blank') return null as Return;

  if (fallback === 'relevant') {
    const mostRelevant = enrollments[0];
    return (mostRelevant || null) as Return;
  }

  if (fallback === 'payments') {
    // picking the latest starting cohort.
    // we'll change this if it causes confusion, but right now it's not much of an issue b/c most students have only one cohort.
    const forPayments = _.maxBy(enrollments, (enr) =>
      parseISO(enr.cohort.start_date).getTime(),
    );
    return (forPayments || null) as Return;
  }

  throw new Error('Missing current enrollment');
};
