import { NATIVE_INFO } from '~/lib/native/env';

// "server-side-rendering". webpack converts this to a boolean at build time.
// NOTE: It also resolves to `false` in service-worker context even though `window` is not defined
export const IS_SSR = typeof window === 'undefined';

/**
 * For environment variables loaded at runtime, not buildtime
 * NOTE: will be undefined in server-side rendering & service-worker contexts
 */
export const browserEnv = <T extends string = string>(name: string) => {
  return !IS_SSR && typeof document !== 'undefined'
    ? (document.querySelector<HTMLMetaElement>(`meta[name="${name}"]`)
        ?.content as T) || undefined
    : undefined;
};

export const DEPLOY_ENV = browserEnv<
  'production' | 'staging' | 'preview' | 'development' | 'test'
>('deploy-env');

export const IS_DEV = process.env.NODE_ENV === 'development';

export const FRONTEND_SENTRY_DSN = browserEnv('sentry-dsn');

let ssrMountUrl: URL | undefined;
export const setSSRMountUrl = (str: string) => {
  ssrMountUrl = new URL(str);
};

export const getCurrentUrl = IS_SSR
  ? () => ssrMountUrl!
  : // `self` handles window, worker, and service-worker contexts
    () => self.location;

// app origin e.g. https://classroom.stepful.com
export const getHost = () => getCurrentUrl().origin;

export const PLATFORM: 'web' | 'android' | 'ios' =
  NATIVE_INFO?.platform || 'web';

export const NATIVE_VERSION = NATIVE_INFO?.version || null;
