import { IS_DEV, NATIVE_VERSION, PLATFORM } from '~/lib/env';

const SW_SCOPE = IS_DEV ? '/packs-dev/' : '/packs/';
const SW_PATH = `${SW_SCOPE}stepful-sw.js`;

// TODO:
// - UI to prompt install app: https://www.npmjs.com/package/react-ios-pwa-prompt

const registerServiceWorker = async () => {
  if (typeof window === 'undefined' || !('serviceWorker' in navigator))
    return null;

  await new Promise<void>((resolve) => {
    if (document.readyState === 'complete') {
      resolve();
    } else {
      window.addEventListener('load', () => resolve());
    }
  });

  try {
    const registration = await navigator.serviceWorker.register(
      `${SW_PATH}?app_platform=${PLATFORM}&app_version=${NATIVE_VERSION || ''}`,
      {
        scope: SW_SCOPE,
      },
    );

    console.log('[sw client] registered');

    return registration;
  } catch (error) {
    console.error('[sw client] registration failed:', error);
    return null;
  }
};

let registerServiceWorkerPromise: ReturnType<typeof registerServiceWorker>;

export const getSWRegistration = () =>
  (registerServiceWorkerPromise ||= registerServiceWorker());
