import { useToast } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import {
  useApiBasePath,
  useCurrentEnrollment,
  useDynamicData,
} from '~/bundles/Classroom/data';
import { useCourseContentRoutesParams } from '~/bundles/Classroom/routes';
import type { CourseWidgetOptions } from '~/bundles/Classroom/types';
import type {
  GroupProjectType,
  NavGroupNamespace,
} from '~/components/admin/CourseContentEditor/const';
import type { QuizQuestion as EditorQuizQuestion } from '~/components/admin/QuestionsEditor';
import type { CardType, LessonType } from '~/lib/const/courseContent';
import { trackError } from '~/lib/errorTracking';
import { createProvider } from '~/lib/providerHelpers';
import { request } from '~/lib/request';
import { parseInt } from '~/lib/utils';
import { keyBy } from '~/lib/utils/collections';
import { type GraduationStatus } from '~/queries/grades';

import {
  getActivePageData,
  getLessonProgressRatio,
  getLessonsPerDay,
  getLessonsPerWeek,
  transformLessonDatas,
} from './courseDataHelpers';

export type { UpcomingSchedule } from '~/queries/upcoming_schedules';

const NULL_CONTENT_NAMESPACE = 'null_content';

// currently this represents a week
export type ApiCourseNavGroup = {
  id: RecordId;
  namespace: NavGroupNamespace | typeof NULL_CONTENT_NAMESPACE;
  name: string;
  description?: string; // only for 'null_content'
  locked_until?: TimestampString;
  lesson_containers: ApiCourseLessonContainer[];
};

export type CourseNavGroup = Omit<ApiCourseNavGroup, 'lesson_containers'> & {
  lesson_containers: CourseLessonContainer[];
};

export type CourseModule = {
  id: RecordId;
  name: string;
};

type SocrateaseConfig = {
  /** Socratease user id */
  soc_user_id: string | null;
  init_payload: {
    client_id: string;
    payload_json: string;
    hmac: string;
  };
};

export type StudentQuizQuestion = Pick<
  EditorQuizQuestion,
  | 'id'
  | 'title'
  | 'question_type'
  | 'choices'
  | 'free_response_min_length'
  | 'free_response_max_length'
  | 'accepted_file_types'
  | 'grading_type'
>;

export type LessonStatus = 'completed' | 'in_progress' | 'not_started';

export type LessonCard = {
  card_type: CardType;
  title: string | null;
  content: string | null;
  embed_url: string | null;
  links: { name: string; url?: string | null }[] | null;
};

type ApiCourseLessonBase = {
  // TODO(content-model-2): replace this with `content_lesson_id`. this is only still used by `group_project_item_id`
  legacy_item_id: string; // unique identifier (UUID) for the lesson.
  content_lesson_id: RecordId; // unique identifier (record id) for this scheduled-lesson's content.
  scheduled_lesson_id: RecordId; // unique identifier (record id) for this scheduled-lesson.

  name: string;

  deadline_at: TimestampString;
  deliver_at: TimestampString; // NOTE: the time and timezone are ignored by some code

  lesson_type: LessonType;

  locked?: boolean; // currently unused by the backend
  optional?: boolean;
  description?: string;
  cards?: LessonCard[];
};

export type CourseLessonTypeSpecifics = {
  // for all assignment lesson_types:
  // 'quiz' | 'exam' | 'group_project' | 'simtics_learn' | 'simtics_test' | 'nha_mock_exam'
  assignment?: {
    id: RecordId | null; // null in preview-mode
  };

  // for 'lecture' | 'group_project' | 'group_session'
  schedule_start_at?: TimestampString;
  pending_results_until?: TimestampString;
  // for 'quiz' | 'exam':
  quiz?: {
    external_id: string;
    provider: 'socratease';
    duration_seconds: number | null;
    question_count: number | null;
    confirm_before_start: boolean;
    can_view_results: boolean;
    min_passing_score: number | null; // integer [0, 100]
    lesson_coverage: string | null;
  };
  // for 'nha_mock_exam'
  nha_mock_exam_info?: {
    question_count: number;
    duration_minutes: number; // TODO: switch this to seconds
    max_attempts: number;
    min_passing_score: number;
    access_type: 'access_portal' | 'register_certification' | 'take_mock_exam';
  };
  // for 'survey'`:
  typeform?: {
    external_id: string;
    week_field: number | 'end';
  };
  // for 'markup'
  markup?: {
    content: string | null;
  };
  // for 'video'
  video?: {
    url: string;
    duration_s: number | null;
  };
  // for 'group_project'
  group_project?: {
    group_project_type: GroupProjectType;
  };
  // for 'group_session' | 'group_project_intro' | 'group_project_submission' | 'group_project_feedback' | 'group_project_sessions_summary'
  group_project_item_id?: string | null;
  // for 'simtics_learn' | 'simtics_test' | 'nha_mock_exam'
  lti_lesson?: {
    provider: 'simtics' | 'nha';
    course_key: string;
  };
};

export type ApiCourseLesson = ApiCourseLessonBase & CourseLessonTypeSpecifics;

export type CourseLesson = ApiCourseLesson & {
  status: LessonStatus;
  progressRatio: number;
  path: string;
  container: CourseLessonContainer;
  deliverDate: DateString;
  deliverWeekIndex: number;
  deadlineAt: number;
};

// this data is ONLY in the parent CourseLesson object (not in the children i.e. `lessons: CourseLesson[]`)
export type ApiCourseLessonContainer = {
  id: RecordId; // ContentLessonContainer.depth_type_container id
  module_id: RecordId; // ContentLessonContainer.depth_type_module id
  unit_name?: string; // e.g. '1.3'
  name: string | null;
  lessons: ApiCourseLesson[];
};

export type CourseLessonContainer = Omit<
  ApiCourseLessonContainer,
  'lessons'
> & {
  status: LessonStatus;
  progressRatio: number;
  lessons: CourseLesson[];
  // lessons: [CourseLesson, ...CourseLesson[]]; // use this once we set typescript `noUncheckedIndexedAccess: true`
  module: CourseModule;
  navGroup: CourseNavGroup;
} & Pick<CourseLesson, 'deliverDate' | 'deliverWeekIndex'>;

export type CourseLessonSurvey = WithNonNullable<CourseLesson, 'typeform'> & {
  lesson_type: 'survey';
};
export type CourseLessonLti = WithNonNullable<
  CourseLesson,
  'assignment' | 'lti_lesson'
> & {
  lesson_type: 'simtics_learn' | 'simtics_test' | 'nha_mock_exam';
};
export type CourseLessonNha = WithNonNullable<
  CourseLesson,
  'assignment' | 'lti_lesson' | 'nha_mock_exam_info'
> & {
  lesson_type: 'nha_mock_exam';
};

// data passed from the backend
export type ApiCourseData = {
  preview_mode?: boolean;
  course: { name: string };
  modules: CourseModule[];
  nav_groups: ApiCourseNavGroup[];
  peer_group?: {
    slack_channel_id: string | null;
    members: {
      name: string;
    }[];
  } | null;
  content_substitutions?: Record<string, string> | null;
  current_week_index: number;
  course_widget_options: CourseWidgetOptions;
};

// See `Grading` `student_facing_graduation_warnings`
export type GraduationWarningType =
  | 'grade_below_threshold'
  | 'remaining_tuition_balance'
  | 'upload_diploma_doc'
  | 'upload_idcard_doc'
  | 'complete_exit_survey';

export type OverdueAssignment = {
  id: RecordId;
  attempted: boolean;
  missed_points: number;
  assignment_score: number | null;
  /** means the task is part of the student's current StudentActionPlan */
  actionable: boolean;
  max_progressive_points: number | null;
};

export type StudentAction = {
  type: 'low_grade' | 'missed_exam';
  due_at: TimestampString;
  completed_at: DateString | null;
  snoozed_until: DateString | null;
  total_assignments: number;
  total_points: number;
  completed_points: number;
  completed_assignment_count: number;
};

export type AssignmentUserProgress = {
  content_lesson_id: RecordId;
  score: number | null; // [0, 100]
  completed_or_missing: boolean;
  extended_deadline_at?: TimestampString | null; // override for lesson `deadline_at`
  max_attempts: number | null; // null denotes unlimited attempts
  remaining_attempts: number | null; // null denotes unlimited attempts
  locked_attempts: boolean; // an optional flag that prevents the user from starting a new attempt
  /** Finished attempts */
  attempts: {
    attempt: number; // the "attempt number", see `Grade#attempt`
    score: number; // integer [0, 100]
    finished_at: TimestampString;
  }[];
};

export type MissedSchedule = {
  name: string;
  deadline_at: TimestampString | null;
  link: string | null;
};

export type ProgressStats = {
  graduation_status: GraduationStatus;
  grade: number;
  grade_categories: {
    category: string;
    score: number | null; // [0, 100]
    weight: number; // [0.0, 1.0]
    extra_credit?: boolean;
  }[];
  overall_ratio: number;
  weeks_remaining: number;
  certificate_link: string | null;
  progress_items: {
    key:
      | 'classes'
      | 'peer_sessions'
      | 'exams'
      | 'assignments'
      | 'certificate_mock_exam'
      | 'participation';
    completed: number;
    total: number;
    missed_schedules?: MissedSchedule[];
    missed_assignment_ids?: RecordId[];
  }[];
  all_grades: AssignmentUserProgress[];
  content_lesson_statuses: {
    [content_lesson_id in RecordId]?: LessonStatus;
  };
  // TODO: de-duplicate this data with the `all_grades` above
  overdue_assignments: OverdueAssignment[] | null;
  suggested_student_actions: StudentAction | null;
  graduation_warnings: GraduationWarningType[] | null;
  socratease_config?: SocrateaseConfig;
};

export type CourseDataHookReturn = ReturnType<typeof useCourseData>;

export type ActivePageData = ReturnType<typeof getActivePageData>;

const [CourseDataProvider, useOptionalCourseData] = createProvider(() => {
  const currentEnrollment = useCurrentEnrollment('blank');

  const params = useCourseContentRoutesParams();

  const course_slug = currentEnrollment?.cohort.slug;

  const enabled = !!currentEnrollment && !!params;

  // TODO: how should we handle errors for these requests?
  const { data: courseData, refetch: refreshCourseData } = useDynamicData(
    'course_data',
    {
      enabled,
      refetchInterval: 5 * 60 * 1000, // every 5 minutes
    },
  );

  const {
    data: progressStats,
    updateData: updateProgressStats,
    refetch: refreshProgressStats,
  } = useDynamicData('progress_stats', {
    enabled,
    refetchInterval: 5 * 60 * 1000, // every 5 minutes
  });

  const cohortFirstMonday = currentEnrollment?.cohort.first_monday;
  const cohortEndDate = currentEnrollment?.cohort.end_date;

  const allModules = courseData?.modules;
  const apiNavGroups = courseData?.nav_groups;

  // - populate the lesson statuses of each lesson: `status`, `progressRatio`
  // - populate the computed field of each lesson: `path`, `deliverDate`, etc.
  // - apply the `extended_deadline_at` overrides
  const transformedLessonDatas = useMemo(() => {
    if (!apiNavGroups || !allModules || !course_slug || !cohortFirstMonday)
      return null;
    return transformLessonDatas(
      apiNavGroups,
      allModules,
      course_slug,
      cohortFirstMonday,
      progressStats,
    );
  }, [cohortFirstMonday, course_slug, allModules, apiNavGroups, progressStats]);
  const { navItems, allContainers, allLessons } = transformedLessonDatas || {};

  const lessonsPerDay = useMemo(() => {
    if (!allLessons) return null;
    return getLessonsPerDay(allLessons);
  }, [allLessons]);

  const lessonsPerWeek = useMemo(() => {
    if (!allLessons) return null;
    if (!cohortFirstMonday || !cohortEndDate) return null;

    return getLessonsPerWeek(allLessons, cohortFirstMonday, cohortEndDate);
  }, [cohortFirstMonday, cohortEndDate, allLessons]);

  const activeScheduledLessonId = parseInt(params?.scheduled_lesson_id);
  const activePageData = useMemo(
    () =>
      navItems && activeScheduledLessonId
        ? getActivePageData(navItems, activeScheduledLessonId)
        : null,
    [navItems, activeScheduledLessonId],
  );

  const { activeLesson } = activePageData || {};

  const activeAssignmentProgress = activeLesson
    ? progressStats?.all_grades.find(
        (g) => g.content_lesson_id === activeLesson.content_lesson_id,
      ) || null
    : null;

  const toast = useToast();

  const apiBasePath = useApiBasePath(undefined, true);

  const [updatingProgress, setUpdatingProgress] = useState(false);
  const updateProgress = async ({
    ...customParams
  }: {
    socratease_quiz?: {
      user_id: string;
      quiz_id: string;
      attempt_id: number;
      started_at: TimestampString;
      finished_at: TimestampString | null;
    };
  } = {}) => {
    if (!apiBasePath) return false;
    if (!activeLesson) {
      void trackError(`updateProgress triggered on invalid page`);
      return false;
    }

    try {
      setUpdatingProgress(true);

      updateProgressStats(
        await request<ProgressStats>({
          method: 'PUT',
          url: `${apiBasePath}/progress`,
          body: {
            ...(customParams || {}),
            content_lesson_id: activeLesson.content_lesson_id,
          },
        }),
      );

      return true;
    } catch (err) {
      void trackError(err as Error, 'update course progress', {
        content_lesson_id: activeLesson.content_lesson_id,
        lesson_type: activeLesson.lesson_type,
      });

      toast({
        status: 'error',
        title: 'An error occurred, please refresh and try again',
      });

      return false;
    } finally {
      setUpdatingProgress(false);
    }
  };

  const overdueAssignments = useMemo(() => {
    if (!allLessons || !progressStats?.overdue_assignments) return null;

    const lessonMap = keyBy(allLessons, (l) => l.assignment?.id);

    return progressStats.overdue_assignments.map(
      ({ id: assignment_id, ...rest }) => {
        const lesson = lessonMap[assignment_id];
        return {
          ...rest,
          assignment_id,
          deadline_at: lesson?.deadline_at,
          item_name: lesson?.name,
          link: lesson?.path,
        };
      },
    );
  }, [allLessons, progressStats?.overdue_assignments]);

  if (
    !cohortFirstMonday ||
    !lessonsPerDay ||
    !courseData ||
    !currentEnrollment ||
    !allContainers ||
    !allLessons ||
    !params ||
    !navItems
  ) {
    return null;
  }

  // 'null_content' namespace is only defined in `CourseOutline.null_course_content`
  const isNullContent =
    navItems.length === 1 && navItems[0].namespace === NULL_CONTENT_NAMESPACE;

  const isClassEnded = (progressStats?.overall_ratio ?? 0) >= 1.0;

  const activeIsoDate = activeLesson?.deliverDate;

  return {
    currentEnrollment,

    progressStats,
    refreshProgressStats,

    overdueAssignments,

    activeCourse: courseData.course,
    navItems,
    modules: courseData.modules,
    peerGroup: courseData.peer_group,
    socrateaseConfig: progressStats?.socratease_config,
    courseWidgetOptions: courseData.course_widget_options,

    ...(activePageData || {}),
    activeIsoDate,
    activeAssignmentProgress,

    allContainers,
    allLessons,
    lessonsPerDay,
    lessonsPerWeek,

    cohort: currentEnrollment.cohort,
    cohortFirstMonday,
    currentWeekIndex: courseData.current_week_index,

    cohort_timing: currentEnrollment.cohort_timing,

    course_slug: currentEnrollment.cohort.slug,

    contentSubstitutions: courseData.content_substitutions,

    params,

    isSaving: updatingProgress,

    previewMode: !!courseData.preview_mode,

    isNullContent,
    isClassEnded,

    updateProgress,
    refresh: async () => (await refreshCourseData({ throwOnError: true })).data,
  };
}, 'CourseData');

export { CourseDataProvider, useOptionalCourseData };

// make sure any usage of `useCourseData` is surrounded by a loading state
// e.g. `if (!useOptionalCourseData()) return someLoadingState`
export const useCourseData = () => {
  const data = useOptionalCourseData();
  if (!data) throw new Error('Missing required course data');
  return data;
};

const EMPTY_ARRAY: never[] = [];

// const isoDateInTimezone = (date: TimeToMillisInput, timezone: string) =>
//   formatTime(date, 'yyyy-MM-dd', timezone);
// export const useWeeklyLessons = () => {
//   const { lessonsPerWeek, cohort } = useCourseData();
//   const todayIso =
//     // use cohort's timezone b/c that's the timezone for the date in `deliver_at.slice(0, 10)`
//     isoDateInTimezone(useDevelopmentTodayDate(), cohort.timezone) || '';
//   return lessonsPerWeek[todayIso] || EMPTY_ARRAY;
// };

/**
 * Gets the CourseLessons for the active day i.e. `lessonsPerDay[currentlyViewedDay]`
 */
export const useActiveDailyLessons = () => {
  const { activeLesson, lessonsPerDay, activeIsoDate } = useCourseData();

  const activeDailyLessons =
    (activeIsoDate && lessonsPerDay[activeIsoDate]) || EMPTY_ARRAY;

  return {
    activeLesson,
    activeDailyLessons,
    progressRatio: getLessonProgressRatio(activeDailyLessons),
  };
};
