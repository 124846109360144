import {
  type TableCellProps,
  type TableColumnHeaderProps,
  type TableContainerProps,
  type TableProps,
  type TableRowProps,
  Button,
  Icon,
  Input,
  Link,
  Select,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import * as _ from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import type { DeepPartial, Path, PathValue } from 'react-hook-form';

import { FormatCurrency, FormatTime } from '~/components/intl';
import { useIsomorphicLayoutEffect } from '~/lib/hooks/useIsomorphicLayoutEffect';
import { type TimeToMillisInput, parseNum, timeToMillis } from '~/lib/utils';

export const TABLE_DATETIME_FORMAT = 'MMM do, yyyy h:mm a';
export const TABLE_DATE_FORMAT = 'MMM do, yyyy';

export const cellInvalidState = <em>Bad format</em>;

export const FORMATTERS = {
  number: null,
  email: (v) =>
    typeof v === 'string' ? (
      <Link href={`mailto:${v}`} isExternal>
        {v}
      </Link>
    ) : (
      cellInvalidState
    ),
  mailto: (v) =>
    typeof v === 'string' ? (
      <Link href={`mailto:${v}`} isExternal>
        Email
      </Link>
    ) : (
      cellInvalidState
    ),
  phone: (v) =>
    typeof v === 'string' ? (
      <Link href={`tel:${v}`} isExternal>
        {v}
      </Link>
    ) : (
      cellInvalidState
    ),
  link: (v) =>
    typeof v === 'string' ? (
      <Link href={v} isExternal>
        {v}
      </Link>
    ) : (
      cellInvalidState
    ),
  time: (v) => (
    <FormatTime
      time={v as TimeToMillisInput}
      format={TABLE_DATETIME_FORMAT}
      excludeCurrentYear
    />
  ),
  date: (v) => (
    <FormatTime
      time={v as TimeToMillisInput}
      format={TABLE_DATE_FORMAT}
      excludeCurrentYear
    />
  ),
  currency: (v) =>
    typeof v === 'number' ? <FormatCurrency value={v} /> : cellInvalidState,
  bool: (v) => <>{v ? '✅' : '⛔️'}</>,

  // text: (v) => (
  //   <pre style={{ whiteSpace: 'pre-wrap' }}>
  //     {v}
  //   </pre>
  // ),
} satisfies { [format in string]: ((v: unknown) => React.ReactNode) | null };

export type FormatType = keyof typeof FORMATTERS;

type EditorParams = { options?: { key: string; label: string }[] };
type RenderEditor = (
  val: any,
  setVal: (v: any) => void,
  params: EditorParams & { isUpdating: boolean },
) => React.ReactNode;

const EDITABLES = {
  bool: (val, setVal) => (
    <Switch isChecked={!!val} onChange={(e) => setVal(e.target.checked)} />
  ),
  number: (val, setVal, { isUpdating }) => (
    <Input
      type="number"
      defaultValue={val ?? ''}
      onBlur={(e) => {
        if ((val ?? '') !== e.target.value) setVal(parseNum(e.target.value));
      }}
      w="full"
      size="sm"
      isDisabled={isUpdating}
    />
  ),
  string: (val, setVal, { isUpdating }) => (
    <Input
      defaultValue={val ?? ''}
      onBlur={(e) => setVal(e.target.value)}
      w="full"
      size="sm"
      isDisabled={isUpdating}
    />
  ),
  select: (val, setVal, { options = [], isUpdating }) => (
    <Select
      value={val ?? ''}
      onChange={(e) => setVal(e.target.value)}
      w="full"
      size="sm"
      isDisabled={isUpdating}
    >
      {options.map((o) => (
        <option key={o.key} value={o.key}>
          {o.label}
        </option>
      ))}
    </Select>
  ),
} satisfies { [format in string]?: RenderEditor };

export const SORTERS = {
  time: (v) => timeToMillis(v) ?? -1,
  bool: (v) => (v ? 0 : v != null ? 1 : 2),
} satisfies { [format in string]?: (v: any) => number };

export const ROW_STYLES_PROP = Symbol('rowStylesProp');

type RowConfig = {
  [row_styles_prop in typeof ROW_STYLES_PROP]?: TableRowProps;
};

type BaseRow = Record<string, unknown>;

type InnerColumn<
  Row extends BaseRow,
  Field extends Path<Row> | ((row: Row) => any),
> = {
  format?:
    | FormatType
    | ((
        fieldValue: Field extends Path<Row>
          ? PathValue<Row, Field>
          : Field extends (...args: any) => any
          ? ReturnType<Field>
          : never,
        index: number,
        row: Row,
      ) => React.ReactNode);
  formatNulls?: boolean;
  field: Field;
  label?: React.ReactNode;
  sortable?: boolean | { by?: Path<Row> | ((row: Row) => any) };
  editable?:
    | boolean
    | keyof typeof EDITABLES
    | (EditorParams & {
        type?: keyof typeof EDITABLES;
      });
} & Pick<
  TableColumnHeaderProps,
  'maxWidth' | 'whiteSpace' | 'width' | 'textTransform'
>;

export type Column<Row extends BaseRow = BaseRow> = InnerColumn<
  Row,
  // TODO: the following causes `fieldValue` to always be `any`
  Path<Row> | ((row: Row) => any)
>;

const clsx = (...tokens: unknown[]) => tokens.filter(Boolean).join(' ');

const EMPTY_OBJ = {};

const stickyLeftProps: TableCellProps = {
  position: 'sticky',
  left: 0,
  bg: 'white',
  zIndex: 1,
  backgroundClip: 'padding-box',
};

export const cellNullState = (
  <Text as="span" color="gray.200">
    -
  </Text>
);

export const ViewTable = <Row extends BaseRow>({
  primaryKey = 'id',
  columns,
  data,
  nullCell = cellNullState,
  hOverflowShadows,
  vOverflowShadows,
  stickyLeftColumns,
  stickyHeader,
  stickyOverflowHeader,
  tableProps = EMPTY_OBJ,
  containerProps = EMPTY_OBJ,
  cellProps = EMPTY_OBJ,
  highlightedRowValue,
  fieldDefaults,
  onUpdateRow,
  onRowClick,
}: {
  primaryKey?: 'id' | keyof Row;
  columns: readonly (Column<Row> | null | false)[];
  data: (Row & RowConfig)[];
  nullCell?: React.ReactNode;
  tableProps?: TableProps;
  containerProps?: TableContainerProps;
  cellProps?: TableCellProps;
  highlightedRowValue?: string | number;
  hOverflowShadows?: boolean;
  vOverflowShadows?: boolean;
  stickyLeftColumns?: number; // integer 1 or more
  stickyHeader?: boolean | string | number; // sticks to top of parent div that doesn't have scroll overflow
  stickyOverflowHeader?: boolean; // sticks to top of parent div that has scroll overflow
  fieldDefaults?: Omit<Column<Row>, 'field' | 'label'>;
  onUpdateRow?: (row: Row, updates: DeepPartial<Row>) => MaybePromise<void>;
  onRowClick?: (row: Row, index: number, evt: React.MouseEvent) => void;
}) => {
  const [customSort, setCustomSort] = useState<{
    sortKey: string;
    asc: boolean;
  } | null>(null);

  const fields = columns.filter(Boolean).map((col, colIndex) => {
    const {
      field,
      format,
      label = typeof field === 'string' ? _.startCase(field) : null,
      sortable,
      editable,
      formatNulls,
      ...thProps
    } = (
      fieldDefaults
        ? {
            ...fieldDefaults,
            ...col,
          }
        : col
    ) as Column<Row>;

    const fieldPath =
      typeof field === 'string' && field.length > 0 ? field : null;

    const getField: (row: Row) => any = _.isFunction(field)
      ? field
      : fieldPath
      ? (row: Row) => _.get(row, fieldPath)
      : () => null;

    // a function that gets the value that we will sort by
    let mapSort: ((row: Row, index: number) => any) | undefined;
    if (sortable) {
      if (typeof sortable === 'object') {
        const { by } = sortable;
        if (_.isFunction(by)) {
          mapSort = by;
        } else if (typeof by === 'string') {
          mapSort = (row) => _.get(row, by);
        }
      }

      if (!mapSort) {
        const formatSort =
          (format &&
            (SORTERS as Record<string, ((row: Row) => any) | undefined>)[
              format as string
            ]) ||
          _.identity;

        mapSort = (row) => formatSort(getField(row));
      }
    }

    const isStickyLeft =
      stickyLeftColumns != null && colIndex < stickyLeftColumns;

    let editor:
      | {
          render?: RenderEditor;
          params?: EditorParams;
        }
      | undefined;
    if (onUpdateRow && editable) {
      const editableType =
        (typeof editable === 'string' && editable) ||
        (typeof editable === 'object' && editable.type) ||
        (typeof format === 'string' && format in EDITABLES && format) ||
        'string';

      const editableParams = typeof editable === 'object' ? editable : {};

      editor = {
        render: (
          EDITABLES as Record<
            string,
            typeof EDITABLES[keyof typeof EDITABLES] | undefined
          >
        )[editableType],
        params: editableParams,
      };
    }

    return {
      label,
      formatNulls,
      isNumeric: format === 'number' || format === 'currency',
      thProps: {
        ...(isStickyLeft ? stickyLeftProps : {}),
        ...(stickyHeader || stickyHeader === 0
          ? {
              ..._.omit(stickyLeftProps, 'left'),
              top: stickyHeader === true ? 0 : stickyHeader,
              zIndex: 2,
              boxShadow: 'inset 0 -1px 0 rgb(237, 242, 247)',
            }
          : {}),
        ...thProps,
      },
      getField,
      fieldPath,
      sortKey: fieldPath ?? `sort-${colIndex}`,
      mapSort,
      cellProps: {
        ...cellProps,
        ...(isStickyLeft ? stickyLeftProps : EMPTY_OBJ),
        whiteSpace: thProps.whiteSpace,
      },
      editor,
      formatter: !format
        ? null
        : _.isFunction(format)
        ? format
        : FORMATTERS[format],
    };
  });

  const localData = useMemo(() => {
    if (!customSort) return data;

    const sortCol = fields.find((f) => f.sortKey === customSort.sortKey);
    if (!sortCol?.mapSort) return data;

    const next = _.sortBy(data, sortCol.mapSort);

    return customSort.asc ? next : next.reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSort, data]);

  // the following code will set the css variable '--shadow-left-offset' to
  // the sum of the width of the first `stickyLeftColumns` columns.
  // TODO: ideally this could be done with only css or with a `ResizeObserver`
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRowRef = useRef<HTMLTableRowElement>(null);
  useIsomorphicLayoutEffect(() => {
    if (!tableContainerRef.current || !tableRowRef.current) return;

    const shadowLeftOffset =
      hOverflowShadows && stickyLeftColumns != null
        ? _.sum(
            (Array.from(tableRowRef.current.children) as HTMLElement[])
              .slice(0, stickyLeftColumns)
              .map((el) => el.offsetWidth),
          )
        : null;

    tableContainerRef.current.style.setProperty(
      '--shadow-left-offset',
      shadowLeftOffset ? `${shadowLeftOffset}px` : null,
    );
  });

  const toast = useToast();

  const getOverFlowY = () => {
    if (stickyOverflowHeader) {
      return 'unset';
    }
    return 'visible';
  };

  const getOverFlowX = () => {
    if (stickyOverflowHeader) {
      return 'unset';
    } else if (stickyHeader) {
      return 'visible';
    }
    return 'auto';
  };

  const [updating, setUpdating] = useState<Record<string, boolean>>({});

  return (
    <TableContainer
      ref={tableContainerRef}
      whiteSpace="normal"
      className={clsx(
        hOverflowShadows && 'h-scroll-shadows',
        vOverflowShadows && 'v-scroll-shadows',
        containerProps.className,
      )}
      overflowX={getOverFlowX()}
      // stickyHeader cannot have a parent with `overflow` other than 'visible'
      // you can try and use stickyOverflowHeader instead
      overflowY={getOverFlowY()}
      height="auto"
      {...containerProps}
    >
      <Table variant="simple" {...tableProps}>
        <Thead
          bg="white"
          {...(stickyOverflowHeader
            ? {
                pos: 'sticky',
                top: 0,
                zIndex: 2,
              }
            : {})}
        >
          <Tr ref={tableRowRef}>
            {fields.map((f, i) => {
              let content = f.label;

              if (f.mapSort) {
                content = (
                  <Button
                    variant="ghost"
                    fontSize="inherit"
                    fontWeight="inherit"
                    textTransform="inherit"
                    height="auto"
                    py="0.5"
                    pl="2"
                    pr="0"
                    my="-0.5"
                    ml="-2"
                    mr="0"
                    whiteSpace="inherit"
                    display="inline-flex"
                    alignItems="center"
                    onClick={() => {
                      setCustomSort((prev) => {
                        if (!prev || prev.sortKey !== f.sortKey)
                          return {
                            sortKey: f.sortKey,
                            asc: true,
                          };
                        if (prev.sortKey === f.sortKey && prev.asc)
                          return { sortKey: f.sortKey, asc: false };
                        return null;
                      });
                    }}
                  >
                    {content}
                    <Icon
                      fontSize="1.25rem"
                      viewBox="0 0 24 24"
                      aria-label={`change to sort ${
                        customSort?.asc ? 'descending' : 'ascending'
                      }`}
                      __css={{
                        opacity: customSort?.sortKey === f.sortKey ? 1 : 0.4,
                        transform:
                          customSort?.sortKey === f.sortKey && !customSort.asc
                            ? 'rotate(-180deg)'
                            : undefined,
                        transition: 'transform 0.2s',
                        transformOrigin: 'center',
                      }}
                    >
                      <path
                        fill="currentColor"
                        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                      />
                    </Icon>
                  </Button>
                );
              }

              return (
                <Th
                  key={i}
                  isNumeric={f.isNumeric}
                  {...f.thProps}
                  minW={f.thProps.width}
                >
                  {content}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {localData.map((row, index) => {
            const key = (row as any)[primaryKey] ?? index;

            const isUpdating = !!updating[key];

            const isHighlighted =
              highlightedRowValue != null && key === highlightedRowValue;

            return (
              <Tr
                key={key}
                onClick={
                  onRowClick
                    ? (evt) => {
                        onRowClick(row, index, evt);
                      }
                    : undefined
                }
                _hover={
                  onRowClick && !isHighlighted
                    ? {
                        cursor: 'pointer',
                        bg: '#fdfdfd',
                      }
                    : undefined
                }
                aria-selected={isHighlighted}
                bg={isHighlighted ? '#f3f3f3' : undefined}
                {...(row[ROW_STYLES_PROP] || {})}
              >
                {fields.map((f, j) => {
                  const value = f.getField(row);

                  let cell = null;
                  if (f.editor?.render) {
                    const onEdit = async (next: any) => {
                      const updates = {} as DeepPartial<Row>;
                      _.set(updates, f.fieldPath!, next);
                      try {
                        const res = onUpdateRow!(row, updates);
                        if (res instanceof Promise) {
                          try {
                            setUpdating((prev) => ({ ...prev, [key]: true }));
                            await res;
                          } finally {
                            setUpdating(({ [key]: _c, ...prev }) => prev);
                          }
                        }
                      } catch (err) {
                        toast({
                          title: (err as Error).message,
                          status: 'error',
                        });
                      }
                    };
                    cell = f.editor.render(value, onEdit, {
                      ...f.editor.params,
                      isUpdating,
                    });
                  } else if (value == null && !f.formatNulls) {
                    cell = nullCell;
                  } else if (f.formatter) {
                    cell = f.formatter(value as never, index, row);
                  } else if (value == null && f.formatNulls) {
                    cell = nullCell;
                  } else {
                    cell = value;
                  }

                  return (
                    <Td key={j} isNumeric={f.isNumeric} {...f.cellProps}>
                      {cell}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
