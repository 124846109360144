import { useEffect } from 'react';

import { trackError } from '~/lib/errorTracking';
import { request } from '~/lib/request';

export function useUpdateTimezone() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    request({ url: '/timezone', method: 'PUT', body: { timezone } }).catch(
      trackError,
    );
  }, [timezone]);

  return timezone;
}
