/** Like `groupBy`, but maintains the order of the original array */
export const maintainedGroupBy = <T, K>(
  arr: T[],
  keyFn: (t: T) => K,
): T[][] => {
  const groups: T[][] = [];
  const keyedGroups = new Map<K, T[]>();
  for (const item of arr) {
    const key = keyFn(item);
    let group = keyedGroups.get(key);
    if (!group) {
      group = [];
      keyedGroups.set(key, group);
      groups.push(group);
    }
    group.push(item);
  }
  return groups;
};

/** Like `lodash.keyBy`, but has the actual Key type. */
export const keyBy = <T, K extends string | number>(
  arr: T[],
  keyFn: (t: T) => K | null | undefined,
) => {
  const out = {} as { [key in K]?: T };
  for (const item of arr) {
    const key = keyFn(item);
    if (key != null) out[key] = item;
  }
  return out;
};
