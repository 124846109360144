import {
  Box,
  Button,
  Divider,
  Icon,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { addMonths, format } from 'date-fns';
import React from 'react';

import { CorrectIcon, SpecialAssignmentIcon } from '~/bundles/Classroom/icons';
import { FormatCurrency } from '~/components/intl';
import { Router } from '~/components/Link';
import { TextLogo } from '~/components/Logo';
import { Markdown } from '~/components/Markdown';
import { YoutubeEmbed } from '~/lib/contentEmbed';
import { useUrlQuery } from '~/lib/hooks/location';
import { PageDataLoader, usePageData } from '~/lib/pageData';
import { request } from '~/lib/request';
import { useRouteParams } from '~/lib/routeBuilder';
import { trackEvent } from '~/lib/trackingHelpers/eventTracking';

import { goToAccountCreation } from './PaymentSuccessful';
import { ROUTES } from './routes';
import type { PaymentPlanKind } from './signupFlowData';

type PaymentPlan = {
  id: RecordId;
  kind: PaymentPlanKind;
  public_amount: number;
  installments_number: number | null;
};

type BundlePaymentPlan = PaymentPlan & {
  public_title: string;
  public_subtitle: string | null;
  public_cta: string | null;
  public_banner: string | null;
  difference_owing?: number | null;
};

type PageData = {
  bundle_details: {
    name: string;
    payment_plan: BundlePaymentPlan;
    kickoff_date: string;
    first_payment_due_date: string | null;
  };
  current_payment_plan: PaymentPlan;
  core_enrollment_id: RecordId;
};

const ListItemWithCheckmark = ({ children }: { children: React.ReactNode }) => {
  return (
    <ListItem display="flex" flexDirection="row">
      <ListIcon as={CorrectIcon} color="green.600" boxSize={4} />
      <Box fontSize={{ base: 'xs', md: 'sm' }}>{children}</Box>
    </ListItem>
  );
};

const BundleCard: React.FC<{
  bundlePaymentPlan: BundlePaymentPlan;
  kickoffDate: string;
  currentPaymentPlan: PaymentPlan;
  firstInstallmentPaymentDueDate: string | null;
}> = ({
  bundlePaymentPlan,
  kickoffDate,
  firstInstallmentPaymentDueDate,
  currentPaymentPlan,
}) => {
  const upfrontPaymentDate = addMonths(new Date(), 1);
  const upfrontPaymentFormattedDate = format(upfrontPaymentDate, 'MMMM d');
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <Text fontWeight="bold" fontSize="md" mt="2">
          {bundlePaymentPlan.public_title}
        </Text>
        <Box fontSize="sm" mt="1">
          {bundlePaymentPlan.kind === 'installments' ? (
            <>
              <Text as="span" fontWeight="bold">
                Plan updates to:
              </Text>
              <UnorderedList marginLeft="6" marginTop="2">
                <ListItem>
                  <Text as="s" color="gray.400">
                    <FormatCurrency value={currentPaymentPlan.public_amount} />
                  </Text>{' '}
                  <FormatCurrency value={bundlePaymentPlan.public_amount} />{' '}
                  every two weeks
                </ListItem>
                <ListItem>
                  <Text as="s" color="gray.400">
                    {currentPaymentPlan.installments_number}
                  </Text>{' '}
                  {bundlePaymentPlan.installments_number} installments, first
                  installment due{' '}
                  {firstInstallmentPaymentDueDate &&
                    new Date(firstInstallmentPaymentDueDate).toLocaleDateString(
                      'en-US',
                      {
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'UTC',
                      },
                    )}
                </ListItem>
              </UnorderedList>
            </>
          ) : (
            bundlePaymentPlan.difference_owing &&
            bundlePaymentPlan.difference_owing > 0 && (
              <>
                <Text as="span">
                  additional{' '}
                  <FormatCurrency value={bundlePaymentPlan.difference_owing} />,
                  due by {upfrontPaymentFormattedDate}
                </Text>
                <Text fontSize="sm" mt={2}>
                  {bundlePaymentPlan.public_subtitle}
                </Text>
              </>
            )
          )}
        </Box>
        <Box>
          <List spacing={4} mt={{ base: 4, md: 6 }}>
            <ListItemWithCheckmark>
              Learn the fundamentals of{' '}
              <Text as="span" fontWeight="bold">
                infant, child and teenage patient care
              </Text>
            </ListItemWithCheckmark>
            <ListItemWithCheckmark>
              Prepare for{' '}
              <Text as="span" fontWeight="bold">
                pediatric medical assistant interviews
              </Text>
            </ListItemWithCheckmark>
            <ListItemWithCheckmark>
              Class starts on{' '}
              {new Date(kickoffDate).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
              })}
              , 2 weeks after the medical assistant program ends
            </ListItemWithCheckmark>
            <ListItemWithCheckmark>
              Weekly live classes on Wednesday at 7:30p ET
            </ListItemWithCheckmark>
          </List>
        </Box>
      </Box>
    </>
  );
};

export const ConfirmTimingModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const toast = useToast();
  const { bundle_slug: bundleSlug } = useRouteParams(ROUTES.bundle) || {};
  const coreEnrollmentId = usePageData<PageData>().core_enrollment_id;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackEvent(
          'Bundle Confirm Class Timing Modal User Responded',
          {
            bundle_curriculum: bundleSlug,
            user_response: 'clicked_outside_modal',
          },
          {
            enrollmentId: coreEnrollmentId,
          },
        );
        onClose();
      }}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent paddingY="8">
        <ModalHeader textAlign="center">
          Please Confirm Your Class Schedule Availability
        </ModalHeader>
        <ModalBody>
          <Text textAlign="center" fontSize="sm">
            The Pediatric classes are scheduled to start 2 weeks after the
            medical assistant program ends <br />
            <br />
            These classes are set to take place every{' '}
            <Text fontWeight="bold" as="span">
              Wednesday at 7:30 PM ET.{' '}
            </Text>
            <br />
            <br />
            Can you confirm if this schedule works for you?
          </Text>
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column">
          <Button
            onClick={() => {
              trackEvent(
                'Bundle Confirm Class Timing Modal User Responded',
                {
                  bundle_curriculum: bundleSlug,
                  user_response: 'clicked_confirm_timing',
                },
                {
                  enrollmentId: coreEnrollmentId,
                },
              );
              onConfirm();
            }}
            colorScheme="brand"
            type="submit"
            mb="4"
            py="6"
            w="full"
          >
            Confirm timing works
          </Button>
          <Button
            variant="link"
            colorScheme="brand"
            onClick={() => {
              trackEvent(
                'Bundle Confirm Class Timing Modal User Responded',
                {
                  bundle_curriculum: bundleSlug,
                  user_response: 'clicked_class_timing_does_not_work',
                },
                {
                  enrollmentId: coreEnrollmentId,
                },
              );
              void goToAccountCreation(toast);
            }}
          >
            Class timing does not work
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const LearnMoreModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const { bundle_slug: bundleSlug } = useRouteParams(ROUTES.bundle) || {};
  const coreEnrollmentId = usePageData<PageData>().core_enrollment_id;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackEvent('Bundle Intro Modal User Responded', {
          bundle_curriculum: bundleSlug,
          user_response: 'clicked_outside_modal',
        });
        onClose();
      }}
      size={{ base: 'sm', md: 'md' }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          Want to work with children?
        </ModalHeader>
        <ModalBody>
          <Text textAlign="center" fontWeight="bold" fontSize="sm">
            Boost your resume with Stepful’s 4-week Pediatrics Specialization!
          </Text>
          <Box rounded="md" my="4" height="180px" overflow="hidden">
            <YoutubeEmbed
              url="https://www.youtube.com/watch?v=UOvLAfWZYq4"
              autoPlay={false}
            />
          </Box>
          <List spacing={3}>
            <ListItemWithCheckmark>
              Learn the fundamentals of{' '}
              <Text as="span" fontWeight="bold">
                infant, child and teenage patient care
              </Text>
            </ListItemWithCheckmark>

            <ListItemWithCheckmark>
              Prepare for{' '}
              <Text as="span" fontWeight="bold">
                pediatric medical assistant interviews
              </Text>
            </ListItemWithCheckmark>

            <ListItemWithCheckmark>
              Access to{' '}
              <Text as="span" fontWeight="bold">
                1-3 pediatric related job postings
              </Text>{' '}
              (subject to availability)
            </ListItemWithCheckmark>
          </List>
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column">
          <Button
            onClick={() => {
              trackEvent(
                'Bundle Intro Modal User Responded',
                {
                  bundle_curriculum: bundleSlug,
                  user_response: 'clicked_learn_more',
                },
                {
                  enrollmentId: coreEnrollmentId,
                },
              );
              onClose();
            }}
            colorScheme="brand"
            type="submit"
            mb="4"
            py="6"
            w="full"
          >
            Learn more
          </Button>
          <Button
            variant="link"
            colorScheme="brand"
            onClick={() => {
              trackEvent(
                'Bundle Intro Modal User Responded',
                {
                  bundle_curriculum: bundleSlug,
                  user_response: 'clicked_skip',
                },
                {
                  enrollmentId: coreEnrollmentId,
                },
              );
              void goToAccountCreation(toast);
            }}
          >
            Skip
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const BundlePageInner: React.FC = () => {
  const {
    bundle_details: {
      name: bundleName,
      payment_plan: bundlePaymentPlan,
      kickoff_date: kickoffDate,
      first_payment_due_date: firstPaymentDueDate,
    },
    current_payment_plan: currentPaymentPlan,
    core_enrollment_id: coreEnrollmentId,
  } = usePageData<PageData>();

  const [query, updateQuery] = useUrlQuery();
  const { slug: curriculumSlug, bundle_slug: bundleSlug } =
    useRouteParams(ROUTES.bundle) || {};
  const toast = useToast();

  const shouldOpenModal = query?.bundle_modal === 'true';
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: shouldOpenModal,
  });

  const {
    isOpen: isConfirmTimingOpen,
    onClose: isConfirmTimingClose,
    onOpen: onOpenConfirmTiming,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  if (!bundlePaymentPlan || !currentPaymentPlan)
    throw new Error('Bundle or current payment plan not found');

  return (
    <>
      <Box>
        <Box h="16" px="6" display="flex" alignItems="center">
          <TextLogo />
        </Box>
        <Box mx="auto" maxW="container.sm" px="4" pb="12">
          <Box>
            <Text
              fontSize={{ base: 'xl', md: '2xl' }}
              lineHeight="short"
              fontWeight="600"
              mt="2"
            >
              Want to add on a {bundleName} Specialization?
            </Text>
          </Box>

          <Box
            borderWidth="1px"
            borderColor="brand.500"
            backgroundColor="red.50"
            borderRadius="md"
            position="relative"
            mt={10}
            px={5}
            py={6}
          >
            <BundleCard
              bundlePaymentPlan={bundlePaymentPlan}
              kickoffDate={kickoffDate}
              currentPaymentPlan={currentPaymentPlan}
              firstInstallmentPaymentDueDate={firstPaymentDueDate}
            />
            {bundlePaymentPlan.public_banner ? (
              <Tag
                position="absolute"
                top="-4"
                left="2"
                backgroundColor="brand.500"
                color="white"
                fontSize="xs"
                fontWeight="bold"
                py="2"
              >
                <Icon as={SpecialAssignmentIcon} w="3" h="3" marginRight="2" />
                {bundlePaymentPlan.public_banner}
              </Tag>
            ) : null}
          </Box>
          {!isOpen && (
            <Box
              position={{ base: 'fixed', md: 'static' }}
              shadow={{ base: 'dark-lg', md: 'none' }}
              bottom={0}
              left={0}
              w="full"
              bg="white"
              p={{ base: '4', md: 0 }}
              mt={{ base: '0', md: 6 }}
              zIndex={{ base: 1, md: 'auto' }}
            >
              <Button
                onClick={async () => {
                  trackEvent(
                    'Bundle Details Page User Responded',
                    {
                      bundle_curriculum: bundleSlug,
                      user_response: 'clicked_add_specialization',
                    },
                    {
                      enrollmentId: coreEnrollmentId,
                    },
                  );
                  onOpenConfirmTiming();
                }}
                colorScheme="brand"
                py={6}
                w="full"
              >
                <Markdown inline>
                  {bundlePaymentPlan.public_cta || 'Yes, add it'}
                </Markdown>
              </Button>
              <Button
                onClick={() => {
                  trackEvent(
                    'Bundle Details Page User Responded',
                    {
                      bundle_curriculum: bundleSlug,
                      user_response: 'clicked_continue_without_it',
                    },
                    {
                      enrollmentId: coreEnrollmentId,
                    },
                  );
                  void goToAccountCreation(toast);
                }}
                mt={{ base: 2, md: 4 }}
                variant="outline"
                colorScheme="brand"
                py={6}
                w="full"
              >
                <Markdown inline>No, continue without it</Markdown>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {(isOpen || isConfirmTimingOpen) && (
        <Box
          as="main"
          minH="100vh"
          alignItems="stretch"
          display="flex"
          flexDir="column"
        >
          <LearnMoreModal
            isOpen={isOpen}
            onClose={() => {
              updateQuery({ bundle_modal: 'false' });
              onClose();
            }}
          />
          <ConfirmTimingModal
            isOpen={isConfirmTimingOpen}
            onClose={isConfirmTimingClose}
            onConfirm={async () => {
              try {
                await request({
                  url: `/curricula/${curriculumSlug!}/signup/add-bundle/${bundleSlug!}`,
                  method: 'PUT',
                  body: {
                    payment_plan_id: bundlePaymentPlan.id,
                  },
                });

                Router.push(
                  `/curricula/${curriculumSlug!}/signup/bundle/${bundleSlug!}/success`,
                );
              } catch (err) {
                return err;
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

type SuccessPageData = {
  bundle_name: string;
  updated_payment_plan: Omit<BundlePaymentPlan, 'public_cta' | 'public_banner'>;
  curriculum_name: string;
};

const BundleSuccessPageInner: React.FC = () => {
  const {
    curriculum_name: curriculumName,
    bundle_name: bundleName,
    updated_payment_plan: updatedPaymentPlan,
  } = usePageData<SuccessPageData>();

  const { slug: curriculumSlug } = useRouteParams(ROUTES.bundle_success) || {};
  const toast = useToast();

  if (!curriculumSlug) throw new Error('No curriculum slug found');
  return (
    <Box>
      <Box h="16" px="6" display="flex" alignItems="center">
        <TextLogo />
      </Box>
      <Box mx="auto" maxW="container.sm" px="6" pb="12" textAlign="center">
        <Box>
          <Text
            fontSize={{ base: 'xl', md: '2xl' }}
            lineHeight="short"
            fontWeight="bold"
          >
            Added {bundleName} Specialization ✅
          </Text>

          <Box mt="12">
            {updatedPaymentPlan.kind === 'installments' ? (
              <>
                <Text fontSize="sm">Your new payment plan:</Text>

                <Text as="span" fontWeight="bold">
                  <FormatCurrency value={updatedPaymentPlan.public_amount} />
                </Text>
                {updatedPaymentPlan.public_subtitle && (
                  <Text as="span" ml="1">
                    {updatedPaymentPlan.public_subtitle} for{' '}
                    {updatedPaymentPlan.installments_number} installments
                  </Text>
                )}
              </>
            ) : (
              updatedPaymentPlan.difference_owing &&
              updatedPaymentPlan.difference_owing > 0 && (
                <Text>
                  A member from the stepful team will reach out to coordinate
                  the additional payment of{' '}
                  <Text fontWeight="bold" as="span">
                    <FormatCurrency
                      value={updatedPaymentPlan.difference_owing}
                    />
                  </Text>
                </Text>
              )
            )}
          </Box>

          <Divider my="8" />

          <Text>
            Create your Stepful account to access the online classroom and start
            learning. You will complete the {curriculumName} program before
            starting the {bundleName} Specialization!
          </Text>

          <Button
            variant="outline"
            colorScheme="brand"
            borderColor="gray.300"
            marginTop="10"
            onClick={() => goToAccountCreation(toast)}
          >
            Create account
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const BundlePage: React.FC = () => {
  return (
    <PageDataLoader>
      <BundlePageInner />
    </PageDataLoader>
  );
};

export const BundleSuccessPage: React.FC = () => {
  return (
    <PageDataLoader>
      <BundleSuccessPageInner />
    </PageDataLoader>
  );
};
