import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useId } from 'react';

import { Markdown } from '~/components/Markdown';

import { StripeCardPaymentForm } from './SignupPaymentForm';
import { type PaymentPlan, useSignupFlow } from './signupFlowData';

type SignupPaymentDrawerProps = {
  paymentPlan?: PaymentPlan;
};

export function SignupPaymentDrawer({ paymentPlan }: SignupPaymentDrawerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading } = useSignupFlow();
  const drawerPlacement = useBreakpointValue<'bottom' | 'right'>({
    base: 'bottom',
    md: 'right',
  });
  const id = useId();
  return (
    <>
      <Button onClick={onOpen} colorScheme="brand" w="full">
        <Markdown inline>
          {paymentPlan?.public_cta || 'Pay to secure your spot'}
        </Markdown>
      </Button>

      <Drawer
        placement={drawerPlacement ?? 'bottom'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            {paymentPlan && (
              <StripeCardPaymentForm
                inputId={id}
                loading={isLoading}
                paymentPlan={paymentPlan}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
