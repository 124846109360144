import React from 'react';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Carousel = ({
  slides,
  customStyle,
  carouselProps,
}: {
  slides: React.ReactNode[];
  customStyle?: React.CSSProperties;
  carouselProps?: Record<string, any>;
}): JSX.Element => {
  const baseStyle = {
    zIndex: 0,
  };

  const componentStyle = { ...baseStyle, ...customStyle };

  const defaultProps = {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
    style: componentStyle,
    height: 100,
    spaceBetween: 30,
    loop: true,
    modules: [Autoplay, FreeMode],
    autoplay: {
      delay: 1500,
      disableOnInteraction: true,
    },
    grabCursor: true,
  };

  const mergedProps = { ...defaultProps, ...carouselProps };
  return (
    <Swiper {...mergedProps}>
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
