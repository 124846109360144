import { useToast } from '@chakra-ui/react';
import {
  type UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useCurrentEnrollment } from '~/bundles/Classroom/hooks/useCurrentEnrollment';
import { ROUTES } from '~/bundles/Classroom/routes';
import {
  type ClassroomProps,
  type DynamicData,
  DYNAMIC_DATA_MAP,
} from '~/bundles/Classroom/types';
import { transformLessonDatas } from '~/lib/courseDataHelpers';
import { useRootData } from '~/lib/pageData';
import { type RequestError, request, toFormData } from '~/lib/request';
import { useRouteParams } from '~/lib/routeBuilder';
import { useEnrollments } from '~/lib/userData';
import { type InputQueryObj, addUrlQuery } from '~/lib/utils';

export const useClassroomConfig = () => useRootData<ClassroomProps>().config;

export { useCurrentEnrollment, useEnrollments };

export const useApiBasePath = <
  OverrideId extends RecordId | null,
  Optional extends boolean,
>(
  overrideId?: OverrideId,
  optional?: Optional,
) => {
  type Return = OverrideId extends RecordId
    ? string
    : Optional extends true
    ? string | null
    : string;

  const currentOrRelevant = useCurrentEnrollment('blank');

  const id = overrideId ?? currentOrRelevant?.id;
  if (id == null) {
    if (optional) return null as Return;
    else throw new Error('Missing current enrollment');
  }

  return `/enrollments/${id}` as Return;
};

/**
 * This hook is just a wrapper around useSWR that does:
 * - disables default revalidations, i.e. the same config as using `useImmutableSWR`
 * - prepends '/enrollments/:current_enrollment_id' to the api route
 */
export const useDynamicData = <Key extends keyof DynamicData>(
  fetchKey: Key,
  {
    enabled = true,
    urlQuery,
    overrideEnrollmentId,

    // default to immutable:
    refetchOnMount = false,
    refetchOnWindowFocus = false,
    refetchOnReconnect = false,
    ...options
  }: Omit<
    UseQueryOptions<DynamicData[Key], RequestError, DynamicData[Key]>,
    'queryKey'
  > & {
    enabled?: boolean;
    urlQuery?: InputQueryObj;
    overrideEnrollmentId?: number;
  } = {},
) => {
  const currentEnrollmentId = useCurrentEnrollment('blank')?.id;
  const enrollmentId = overrideEnrollmentId ?? currentEnrollmentId;

  let pathname: string | null = null;
  if (enrollmentId) {
    pathname = DYNAMIC_DATA_MAP[fetchKey]?.(enrollmentId);
    if (!pathname) throw new Error(`Invalid dynamic data key: ${fetchKey}`);
  } else {
    enabled = false;
  }

  if (pathname && urlQuery) pathname = addUrlQuery(pathname, urlQuery);

  type Data = DynamicData[Key];

  const res = useQuery<Data, RequestError>({
    queryKey: [pathname],
    enabled,
    ...options,
    refetchOnMount,
    refetchOnWindowFocus,
    refetchOnReconnect,
  });

  const queryClient = useQueryClient();

  const updateData = useCallback(
    (data: Data | ((prev: Data | undefined) => Data)) => {
      const newData = queryClient.setQueryData<Data>([pathname], data);
      return newData;
    },
    [queryClient, pathname],
  );

  return { ...res, updateData };
};

export const useEnrollmentResources = () => useDynamicData('resources');

export const useReferralData = () => {
  const { data: { referral_dashboard_link: referralDashboardLink } = {} } =
    useEnrollmentResources();

  return {
    referralDashboardLink,
  };
};

export const useCareerResources = () => {
  const currentEnrollment = useCurrentEnrollment('blank');

  const {
    data,
    refetch: refresh,
    updateData,
    isLoading,
    error: requestError,
  } = useDynamicData('career', {
    refetchOnWindowFocus: true,
    enabled:
      !!currentEnrollment?.can_access_career_portal ||
      !!currentEnrollment?.can_access_resume_services,
  });

  // raise to ErrorBoundary only within the career portal
  const raiseOnError = !!useRouteParams(ROUTES.career_portal);
  if (requestError && raiseOnError) throw requestError;

  return {
    isLoaded: !!data,
    isLoading,
    careerTasks: data?.career_tasks,
    careerEnrolled: !!data?.career_enrolled,
    premiumSiteMatchingEnabled: !!data?.premium_site_matching_enabled,
    careerEnrollable: !!data?.career_enrollable,
    careerEligible: !!data?.career_eligible,
    careerExitEligible: !!data?.career_exit_eligible,
    externshipOptedOut: !!data?.externship && !!data?.externship_opted_out,
    externshipOptOutReason: data?.externship_opt_out_reason || null,
    externshipEligible: !!data?.externship_eligible,
    jobCoachingOptedOut: !!data?.job_coaching_opted_out,
    jobCoachingEligible: !!data?.job_coaching_eligible,
    enrolledInCareerServicesCohort: !!data?.enrolled_in_career_services_cohort,
    careerPreviouslyOptedIn: !!data?.career_previously_opted_in,
    grade: data?.grade,
    hired: !!data?.hired,
    coach: data?.coach,
    graduated: currentEnrollment?.graduation_status === 'graduated',
    placementCoach: data?.placement_coach,
    jobCoaching: data?.job_coaching,
    expectedCoachingStartDate: data?.expected_coaching_start_date,
    resume: data?.resume,
    screeningReports: data?.screening_reports,
    externship: data?.externship,
    canUploadResume: !!data?.can_upload_resume,
    anyOptedOut: data?.externship_opted_out || data?.job_coaching_opted_out,
    allOptedOut: data?.externship_opted_out && data?.job_coaching_opted_out,
    refresh,
    updateData,
    careerQuestionnairesMap: data?.career_forms || {},
    careerWidgetOptions: data?.career_widget_options,
    likelyToGraduate: data?.likely_to_graduate,
    contactClinicFromNumber: data?.contact_clinic_from_number,
    careerServicesOpeningWeek: data?.career_services_opening_week,
    externshipSignupCountsByMonth: data?.externship_signup_counts_by_month,
    maxSignupCountByMonth: data?.max_signup_count_by_month,
    externshipExperienceType: data?.externship_experience_type,
  };
};

export type CareerResourcesHookReturn = ReturnType<typeof useCareerResources>;

export const useResourcesCourseData = () => {
  const { data, isLoading } = useDynamicData('career_resources_course_data', {
    refetchOnWindowFocus: true,
  });

  const enr = useCurrentEnrollment('blank');

  return {
    isLoading,
    ...useMemo(() => {
      const courseData = data?.resources_course_data;
      if (!courseData || !enr) return {};

      const transformed = transformLessonDatas(
        courseData.nav_groups,
        courseData.modules,
        enr.cohort.slug,
        enr.cohort.first_monday,
      );

      return {
        allContainers: transformed.allContainers,
      };
    }, [data, enr]),
  };
};

type ActiveStorageFileWithExternalUrl = ActiveStorageFile & {
  external_url: string | null;
};

export type QuestionResult = {
  score: number | null;
  correct_indices: number[] | null;
  choice_indices: number[] | null; // indices of the choices that the user chose
  free_response: string | null;
  video_recording_session_id: string | null;
  feedback: string | null;
  file: ActiveStorageFileWithExternalUrl | undefined;
  updated_at: TimestampString | null;
};

type NhaApplicationCreatePayload = {
  exam_date: TimestampString;
  registration_proof: File;
};

export const useCreateNhaApplication = () => {
  const currentEnrollment = useCurrentEnrollment('blank');
  const toast = useToast();

  const createNhaApplication = useCallback(
    async (body: NhaApplicationCreatePayload) => {
      if (!currentEnrollment) return;

      await request({
        url:
          DYNAMIC_DATA_MAP.career(currentEnrollment.id) + '/nha_applications',
        method: 'POST',
        body: toFormData(body),
      });

      toast({
        title: 'Thanks for letting us know!',
        status: 'success',
      });
    },
    [currentEnrollment, toast],
  );

  return { createNhaApplication };
};

export type CreateNhaApplication = NonNullable<
  ReturnType<typeof useCreateNhaApplication>
>;
