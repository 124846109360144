import { type BoxProps, Box, Skeleton } from '@chakra-ui/react';

export const LoadingShimmer: React.FC<BoxProps> = (props) => (
  <Box {...props} data-loading>
    <Skeleton h="24px" w="60%" mb="48px" />
    <Skeleton h="10px" w="80%" mb="20px" />
    <Skeleton h="10px" mb="20px" />
    <Skeleton h="10px" mb="48px" />
    <Skeleton h="10px" mb="20px" />
    <Skeleton h="10px" w="80%" mb="20px" />
    <Skeleton h="10px" w="80%" />
  </Box>
);
