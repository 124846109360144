import {
  type BoxProps,
  type UseRadioProps,
  Box,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import React from 'react';

import { parseInt } from '~/lib/utils';

export const BigButtonRadio: React.FC<
  UseRadioProps & { targetProps?: BoxProps; children: React.ReactNode }
> = ({ children, targetProps = {}, ...radioProps }) => {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex="1">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="md"
        color="gray.500"
        _hover={{
          boxShadow: 'base',
        }}
        _checked={{
          color: 'gray.800',
          borderColor: 'gray.500',
          boxShadow: 'base',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        transition="ease 300ms"
        transitionProperty="color, background-color, box-shadow, border"
        px={5}
        py={4}
        fontSize="md"
        {...targetProps}
        data-group // allows usage of `_groupChecked` in children
      >
        {children}
      </Box>
    </Box>
  );
};

export type BigButtonSelectProps<Value extends string | number> = {
  name?: string;
  value: Value | null;
  onChange: (val: Value | null) => void;
  options: readonly {
    key: Value;
    label: React.ReactNode;
    sub_label?: React.ReactNode;
  }[];
  targetProps?: BoxProps;
  containerProps?: BoxProps;
};

export const BigButtonSelect = <Value extends string | number>({
  name,
  value,
  onChange,
  options,
  targetProps,
  containerProps = {},
}: BigButtonSelectProps<Value>): JSX.Element => {
  const isNum = options.some((o) => typeof o.key === 'number');

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: (val) =>
      onChange(
        (val === '' ? null : isNum ? parseInt(val) : val) as Value | null,
      ),
    value: value ?? '',
  });

  const selectedIndex =
    value != null ? options.findIndex((o) => o.key === value) : undefined;

  return (
    <Box
      display="flex"
      flexDir="column"
      gap="4"
      {...containerProps}
      {...getRootProps()}
      css={
        selectedIndex != null
          ? `& > label:not(:nth-of-type(${selectedIndex + 1})) > div {
              border-color: var(--chakra-colors-gray-200);
              color: var(--chakra-colors-gray-300);
            }`
          : undefined
      }
    >
      {options.map((opt, index) => {
        return (
          <BigButtonRadio
            key={index}
            targetProps={targetProps}
            {...getRadioProps({ value: opt.key })}
          >
            {opt.label}
          </BigButtonRadio>
        );
      })}
    </Box>
  );
};
